<template>
    <div class="grid grid-cols-12 grid-rows-4 lg:grid-rows-1 gap-6 py-6 w-full h-full">
        <div
            :class="{
                'row-span-4 col-span-12 grid-rows-4': !showGameOptions,
                'row-span-2 lg:row-span-4 col-span-12 lg:col-span-8 2xl:col-span-9 grid-rows-1': showGameOptions
            }">
            <Card class="w-full h-full">
                <div class="p-4 max-h-full w-full h-full">
                    <div class="flex flex-col justify-start items-start w-full h-full max-h-full">
                        <h2 class="mb-6">
                            {{ $t("game.players", { count: gameData.studentGames.length }) }}
                        </h2>
                        <simplebar class="h-0 flex-1 w-full">
                            <div
                                class="flex flex-row justify-between items-center w-full p-3 border-purple-10"
                                v-for="(studentGame, idx) in gameData.studentGames"
                                :class="{
                                    'border-t': idx !== 0,
                                    'bg-gray-100': isStudent(studentGame.student)
                                }"
                                :key="studentGame.id">
                                <div class="flex flex-row justify-start items-center space-x-3">
                                    <StudentAvatar
                                        with-popper
                                        size="small"
                                        :student="studentGame.student" />
                                    <div>
                                        <h3>
                                            {{ studentGame.student.name }}
                                        </h3>
                                        <p
                                            class="-small text-gray-200 mt-1"
                                            v-if="$store.getters.getStudentExperience(studentGame.student)">
                                            {{ $t("global.level") }}
                                            {{ $store.getters.getStudentExperience(studentGame.student).level }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="flex flex-row justify-between items-center w-full p-3 border-t border-purple-10">
                                <div class="flex flex-row justify-start items-center">
                                    <StudentAvatar
                                        size="small"
                                        :student="null" />
                                    <p class="text-gray-200 -small px-4">
                                        {{ $t("game.waitingPlayers") }}
                                    </p>
                                </div>
                            </div>
                        </simplebar>
                    </div>
                </div>
            </Card>
        </div>
        <div
            class="col-span-12 lg:col-span-4 2xl:col-span-3 row-span-2 lg:row-span-4"
            v-if="showGameOptions">
            <Card class="w-full h-full">
                <div class="p-4 max-h-full w-full h-full">
                    <div class="flex flex-col justify-start items-start w-full h-full max-h-full"
                         v-if="$store.getters.isStudentLogin && game.gameType.slug === GAME_TYPES.LICK_3D_GAME">
                        <LickGameOptions :model-value="optionsLickGame" @update:modelValue="onUpdateOptionsLickGame" />
                    </div>
                    <div class="flex flex-col justify-start items-start w-full h-full max-h-full" v-else>
                        <h2 class="mb-6">
                            {{ $t("game.settings") }}
                        </h2>
                        <simplebar class="h-0 flex-1 w-full">
                            <div class="w-full h-full">
                                <h3 class="mb-2">{{ $t("game.share") }}:</h3>
                                <p class="text-gray-400 text-14">
                                    {{ $t("game.onComputer") }}
                                </p>
                                <CustomButton
                                    version="text"
                                    class="inline-flex mt-1"
                                    href="http://adimo.live"
                                    target="_blank">
                                    {{ $t("game.goTo") }} adimo.live
                                </CustomButton>
                                <p class="text-gray-400 text-14 mt-6 lg:mt-4">
                                    {{ $t("game.shareWithStudents") }}
                                </p>
                                <CustomButton
                                    version="text"
                                    class="inline-flex mt-1"
                                    @click.prevent="copyGameLink">
                                    {{ $t("game.copyGameLink") }}
                                </CustomButton>
                                <h3 class="mb-2 mt-12">{{ $t("game.modification") }}:</h3>

                                <div>
                                    <CustomButton
                                        version="text"
                                        class="inline-flex"
                                        v-if="!showNewGameCode"
                                        @click.prevent="showNewGameCode = true">
                                        {{ $t("game.changeGameCode") }}
                                    </CustomButton>

                                    <form
                                        class="w-full mb-6"
                                        v-if="showNewGameCode"
                                        @keyup.enter.prevent="updateGameCode"
                                        @submit.prevent="updateGameCode">
                                        <div class="c__input w-auto">
                                            <p class="text-gray-400 text-14 mb-1">
                                                {{ $t("game.changeGameCode") }}
                                            </p>
                                            <GameCodeInput
                                                class="w-1/2 lg:w-full"
                                                @change="newGameCode = $event" />

                                            <p
                                                class="error"
                                                v-if="errors.code">
                                                {{ errors.code }}
                                            </p>
                                        </div>

                                        <div class="flex flex-row justify-between items-center space-x-2 mt-4">
                                            <CustomButton
                                                version="text"
                                                class="text-error-red"
                                                @click.prevent="cancelNewGameCode">
                                                {{ $t("form.btnCancel") }}
                                            </CustomButton>
                                            <CustomButton
                                                version="text"
                                                type="submit">
                                                {{ $t("form.btnEdit") }}
                                            </CustomButton>
                                        </div>
                                    </form>
                                </div>

                                <div
                                    v-if="game.gameType.slug === GAME_TYPES.CONTEST"
                                    class="mt-2">
                                    <CustomButton
                                        version="text"
                                        class="inline-flex"
                                        v-if="!showMaxSecondsToAnswer"
                                        @click.prevent="showMaxSecondsToAnswer = true">
                                        {{ $t("game.changeMaxSecondsToAnswer") }}
                                    </CustomButton>

                                    <form
                                        class="w-full mb-6"
                                        @submit.prevent="updateGameMaxSecondsToAnswer"
                                        v-if="showMaxSecondsToAnswer">
                                        <p class="text-gray-400 text-14 mb-1">
                                            {{ $t("game.changeMaxSecondsToAnswer") }}
                                        </p>
                                        <div class="c__input full">
                                            <input
                                                step="5"
                                                min="5"
                                                max="40"
                                                type="range"
                                                class="w-full"
                                                v-model.number="maxSecondsToAnswer" />
                                            <p
                                                class="error"
                                                v-if="errors.code">
                                                {{ errors.code }}
                                            </p>
                                        </div>
                                        <div class="flex flex-row justify-between items-center h-4 px-2">
                                            <div
                                                v-for="idx in 8"
                                                :key="idx"
                                                class="h-full relative">
                                                <div class="h-full w-px bg-gray-400" />

                                                <span
                                                    class="text-gray-400 text-11 absolute text-center bottom-0 left-0 transform -translate-x-1/2 translate-y-full">
                                                    {{ idx * 5 }}
                                                </span>
                                            </div>
                                        </div>
                                        <p class="text-gray-400 text-13 mt-6 text-center">
                                            {{ maxSecondsToAnswer }} {{ $t("global.seconds") }}
                                        </p>
                                        <div class="flex flex-row justify-between items-center space-x-2 mt-4">
                                            <CustomButton
                                                version="text"
                                                class="text-error-red"
                                                @click.prevent="cancelMaxSecondsToAnswer">
                                                {{ $t("form.btnCancel") }}
                                            </CustomButton>
                                            <CustomButton
                                                version="text"
                                                type="submit">
                                                {{ $t("form.btnEdit") }}
                                            </CustomButton>
                                        </div>
                                    </form>
                                </div>

                                <div class="mt-2">
                                    <CustomButton
                                        version="text"
                                        class="inline-flex"
                                        v-if="!showWordsCount && canEditWordsCount"
                                        @click.prevent="showWordsCount = true">
                                        {{ $t("game.wordsCount") }}
                                    </CustomButton>

                                    <form
                                        class="w-full mb-6"
                                        @submit.prevent="updateGameWordsCount"
                                        v-if="showWordsCount">
                                        <p class="text-gray-400 text-14 mb-1">
                                            {{ $t("game.wordsCount") }}
                                        </p>
                                        <div class="c__input full">
                                            <input
                                                step="1"
                                                :min="1"
                                                :max="50"
                                                type="range"
                                                class="w-full"
                                                v-model.number="wordsCount" />
                                            <p
                                                class="error"
                                                v-if="errors.wordsCount">
                                                {{ errors.wordsCount }}
                                            </p>
                                        </div>
                                        <div class="flex flex-row justify-between items-center h-4 px-2">
                                            <template v-for="idx in 50">
                                                <div
                                                    :key="idx"
                                                    class="h-full relative"
                                                    v-if="idx === 1 || idx % 5 === 0">
                                                    <div class="h-full w-px bg-gray-400" />

                                                    <span
                                                        class="text-gray-400 text-11 absolute text-center bottom-0 left-0 transform -translate-x-1/2 translate-y-full">
                                                        {{ idx * 1 }}
                                                    </span>
                                                </div>
                                            </template>
                                        </div>
                                        <p class="text-gray-400 text-13 mt-6 text-center">
                                            {{ wordsCount }} {{ $t("global.words") }}
                                        </p>
                                        <div class="flex flex-row justify-between items-center space-x-2 mt-4">
                                            <CustomButton
                                                version="text"
                                                class="text-error-red"
                                                @click.prevent="cancelWordsCount">
                                                {{ $t("form.btnCancel") }}
                                            </CustomButton>
                                            <CustomButton
                                                version="text"
                                                type="submit">
                                                {{ $t("form.btnEdit") }}
                                            </CustomButton>
                                        </div>
                                    </form>
                                </div>

                                <div class="mt-2">
                                    <CustomButton
                                        version="text"
                                        class="inline-flex"
                                        v-if="!showChangeWordGroup"
                                        @click.prevent="showChangeWordGroup = true">
                                        {{ $t("game.changeWordGroup") }}
                                    </CustomButton>

                                    <form
                                        class="w-full mb-6"
                                        @submit.prevent="changeWordGroup"
                                        v-if="showChangeWordGroup">
                                        <p class="text-gray-400 text-14 mb-1">{{ $t("game.changeWordGroup") }}</p>
                                        <div class="c__input full">
                                            <div class="c__select">
                                                <select
                                                    class="form-select"
                                                    name="newWordGroupUuid"
                                                    v-model="newWordGroupUuid">
                                                    <option
                                                        v-for="wordGroup in classroomWordGroups"
                                                        :key="wordGroup.id"
                                                        :value="wordGroup.uuid">
                                                        {{ getWordGroupText(wordGroup) }}
                                                    </option>
                                                </select>
                                            </div>
                                            <p
                                                class="error"
                                                v-if="errors.newWordGroupUuid">
                                                {{ errors.newWordGroupUuid }}
                                            </p>
                                        </div>
                                        <div class="flex flex-row justify-between items-center space-x-2 mt-4">
                                            <CustomButton
                                                version="text"
                                                class="text-error-red"
                                                @click.prevent="cancelNewWordGroupUuid">
                                                {{ $t("form.btnCancel") }}
                                            </CustomButton>
                                            <CustomButton
                                                version="text"
                                                type="submit">
                                                {{ $t("form.btnEdit") }}
                                            </CustomButton>
                                        </div>
                                    </form>
                                </div>

                                <div class="mt-2" v-if="game.gameType.slug === GAME_TYPES.LICK_3D_GAME">
                                    <CustomButton
                                        version="text"
                                        class="inline-flex"
                                        v-if="!showOptionsLickGame"
                                        @click.prevent="showOptionsLickGame = true">
                                        {{ $t("game.changeOptionsLickGame") }}
                                    </CustomButton>
                                    <form
                                        class="w-full mb-6"
                                        @submit.prevent="updateLickGameOptions"
                                        v-if="showOptionsLickGame">
                                        <p class="text-gray-400 text-14 mb-1">{{ $t("game.changeOptionsLickGame") }}</p>
                                        <div class="c__input full">
                                            <div class="c__select">
                                                <label>
                                                    {{ $t("game.gameTime") }}
                                                </label>
                                                <select
                                                    class="form-select"
                                                    name="type"
                                                    v-model="optionsLickGame.type">
                                                    <option value="time">
                                                        Temps
                                                    </option>
                                                    <option value="money">
                                                        Argent
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="c__input full" v-if="optionsLickGame.type === 'time'">
                                            <div class="c__select">
                                                <select
                                                    class="form-select"
                                                    name="min"
                                                    v-model.number="optionsLickGame.min">
                                                    <option value="1">
                                                        1 minute
                                                    </option>
                                                    <option value="5">
                                                        5 minutes
                                                    </option>
                                                    <option value="10">
                                                        10 minutes
                                                    </option>
                                                    <option value="15">
                                                        15 minutes
                                                    </option>
                                                    <option value="20">
                                                        20 minutes
                                                    </option>
                                                    <option value="25">
                                                        25 minutes
                                                    </option>
                                                    <option value="30">
                                                        30 minutes
                                                    </option>
                                                    <option value="45">
                                                        45 minutes
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="c__input full" v-if="optionsLickGame.type === 'money'">
                                            <div class="c__select">
                                                <select
                                                    class="form-select"
                                                    name="money"
                                                    v-model.number="optionsLickGame.money">
                                                    <option value="100">
                                                        100$
                                                    </option>
                                                    <option value="150">
                                                        150$
                                                    </option>
                                                    <option value="200">
                                                        200$
                                                    </option>
                                                    <option value="250">
                                                        250$
                                                    </option>
                                                    <option value="300">
                                                        300$
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="flex flex-row justify-between items-center space-x-2 mt-4">
                                            <CustomButton
                                                version="text"
                                                class="text-error-red"
                                                @click.prevent="showOptionsLickGame = false">
                                                {{ $t("form.btnCancel") }}
                                            </CustomButton>
                                            <CustomButton
                                                version="text"
                                                type="submit">
                                                {{ $t("form.btnEdit") }}
                                            </CustomButton>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </simplebar>
                    </div>
                </div>
            </Card>
        </div>
    </div>
    <div class="mb-6 w-full">
        <Card>
            <div class="flex flex-row justify-between items-center p-3">
                <div class="w-1/3">
                    <div class="inline-block">
                        <CustomButton @click.prevent="$emit('exit')">
                            {{ $t("game.btnQuitGame") }}
                        </CustomButton>
                    </div>
                </div>
                <div class="w-1/3 flex flex-row justify-end items-center">
                    <p
                        class="text-gray-200 -small mr-3"
                        v-if="showCountdownTimer">
                        {{ $t("game.startIn") }}
                    </p>
                    <GameTimer
                        class="block font-medium"
                        :timer="{ minutes: 0, seconds: secondsBeforeStart }"
                        v-if="showCountdownTimer" />
                    <div
                        v-if="canStartGame"
                        class="ml-2">
                        <CustomButton
                            @click.prevent="onClickStart"
                            :disabled="gameData.studentGames.length === 0">
                            {{ $t("game.btnStartGame") }}
                        </CustomButton>
                    </div>
                    <div v-else-if="game && game.creatorId">
                        <p class="text-purple -small ml-2">
                            {{ $t("game.canOnlyBeStartByCreator") }}
                        </p>
                    </div>
                </div>
            </div>
        </Card>
    </div>
</template>

<script>
import Card from "components/cards/Card"
import CustomButton from "components/globals/CustomButton"
import StudentAvatar from "components/globals/StudentAvatar"
import GameTimer from "components/game/GameTimer"
import { msToTime } from "@globals/functions"
import Simplebar from "components/globals/Simplebar"
import moment from "moment"
import GameCodeInput from "components/game/GameCodeInput"
import ajax from "config/ajax"
import * as types from "store/types"
import { GAME_TYPES, WORD_GROUP_TYPES } from "@globals/constants"
import LickGameOptions from "components/game/student/types/lobby/LickGameOptions.vue"

export default {
    name: "LiveGameWaiting",
    components: { LickGameOptions, GameCodeInput, Simplebar, GameTimer, StudentAvatar, CustomButton, Card },
    emits: ["exit", "start"],
    props: {
        wordGroup: Object,
        game: Object,
        gameData: Object
    },
    data() {
        return {
            GAME_TYPES,

            newGameCode: "",
            showNewGameCode: false,

            maxSecondsToAnswer: 15, //default
            showMaxSecondsToAnswer: false,

            wordsCount: 5, //default
            showWordsCount: false,

            showChangeWordGroup: false,
            newWordGroupUuid: null,

            showOptionsLickGame: false,
            optionsLickGame: {
                type: "time", //time or money
                min: 5,
                money: 100
            },

            errors: {},
            secondsBeforeStart: 20 //init at 20 seconds like backend
        }
    },
    created() {
        //if autostart
        if (this.gameData.startedAtMs) {
            this.startCountdownTimer()
        }

        //update at start
        if (this.gameData.maxSecondsToAnswer) {
            this.maxSecondsToAnswer = this.gameData.maxSecondsToAnswer
        }

        if (this.gameData.sections.length > 0) {
            this.wordsCount = this.gameData.sectionsCount * this.gameData.sections[0].words.length

        }

        //init from extra data
        if (this.gameData.game && this.gameData.game.gameTypeSlug === GAME_TYPES.LICK_3D_GAME) {
            this.optionsLickGame.money = this.gameData.extraData.money
            this.optionsLickGame.type = this.gameData.extraData.type
            this.optionsLickGame.min = this.gameData.extraData.min
        }
    },
    watch: {
        game(nextValue) {
            //need to fetch classroom to get custom word groups
            if (nextValue && nextValue.classroomId && this.$store.getters.isUserLogin) {
                //make sure only user can get classroom and list group
                this.$store.dispatch(types.USER_DATA.GET_CLASSROOM_BY_ID, nextValue.classroomId) //fetch specific classroom
                this.$store.dispatch(types.USER_DATA.GET_LIST_GROUPS)
            }
        },
        //make sure we got the feshest data if word group change
        currentWordGroup(wordGroup) {
            this.newWordGroupUuid = wordGroup ? wordGroup.uuid : null
        },

        gameData(nextValue) {
            this.maxSecondsToAnswer = nextValue.maxSecondsToAnswer //update

            if (nextValue.sections.length > 0) {
                this.wordsCount = nextValue.sectionsCount * nextValue.sections[0].words.length //update
            }

            //init from extra data
            if (nextValue.game.gameTypeSlug === GAME_TYPES.LICK_3D_GAME) {
                this.optionsLickGame.money = nextValue.extraData.money
                this.optionsLickGame.type = nextValue.extraData.type
                this.optionsLickGame.min = nextValue.extraData.min
            }

            //start timer when start
            if (nextValue.startedAtMs) {
                this.startCountdownTimer()
            }
            if (nextValue.isStarted) {
                this.stopCountdownTimer()
            }
        }
    },
    computed: {
        wordGroups() {
            let wordGroups = []
            if (this.$store.getters.isUserLogin) {
                wordGroups = this.$store.state.userData.wordGroups
            }
            return wordGroups
        },
        gameClassroom() {
            if (!this.game.classroomId) return null
            return this.$store.state.userData.classrooms.find((c) => c.id === this.game.classroomId) || null
        },
        classroomWordGroups() {
            if (!this.gameClassroom) {
                return this.wordGroups
            }
            let listGroupInClassroom =
                this.$store.state.userData.listGroups.find((lG) => lG.id === this.gameClassroom.listGroupId) || null

            if (!listGroupInClassroom) {
                return this.wordGroups
            }

            return this.wordGroups.filter((wG) => {
                if (wG.type === WORD_GROUP_TYPES.LIST) return true //list
                //for custom type, use list group
                return wG.listGroupId === listGroupInClassroom.id
            })
        },
        currentWordGroup() {
            if (!this.game) return null
            return this.wordGroups.find((wG) => wG.id === this.game.wordGroupId) || null
        },
        isCreator() {
            if (!this.game) return false
            if (this.$store.getters.isUserLogin) {
                return this.game.creatorId === this.$store.state.auth.user.id
            }
            return false
        },
        canEditWordsCount() {
            if (this.game.gameType.slug === GAME_TYPES.LICK_3D_GAME) {
                return false
            }
            return true
        },
        showCountdownTimer() {
            if (!this.game) return false
            if (!this.gameData) return false
            //never show for game lick 
            if (this.game.gameType.slug === GAME_TYPES.LICK_3D_GAME) {
                return false
            }
            return this.gameData.startedAtMs
        },
        canStartGame() {
            if (!this.game) return false
            //if user login, only creator can
            if (this.$store.getters.isUserLogin) {
                return this.game.creatorId === this.$store.state.auth.user.id
            }
            if (this.$store.getters.isStudentLogin) {
                if (this.game.gameType.slug === GAME_TYPES.LICK_3D_GAME) {
                    return true
                }
            }
            return false
        },
        showGameOptions() {
            if (!this.game) return false
            if (this.$store.getters.isUserLogin) {
                return this.game.creatorId === this.$store.state.auth.user.id
            }
            if (this.$store.getters.isStudentLogin) {
                return this.game.gameType.slug === GAME_TYPES.LICK_3D_GAME
            }
            return false
        }
    },
    methods: {
        onUpdateOptionsLickGame(options) {
            this.optionsLickGame = options
            this.updateLickGameOptions()
        },
        cancelNewGameCode() {
            this.showNewGameCode = false
            this.newGameCode = ""
        },
        cancelMaxSecondsToAnswer() {
            this.showMaxSecondsToAnswer = false
            this.maxSecondsToAnswer = this.gameData.maxSecondsToAnswer //reset to current setup
        },
        cancelNewWordGroupUuid() {
            this.showChangeWordGroup = false
            this.newWordGroupUuid = this.currentWordGroup ? this.currentWordGroup.uuid : null
        },
        cancelWordsCount() {
            this.showWordsCount = false
            if (this.gameData.sections.length > 0) {
                this.wordsCount = this.gameData.sectionsCount * this.gameData.sections[0].words.length //reset to current setup
            }
        },
        getWordGroupText(wordGroup) {
            let traduction = wordGroup.traductions.find((t) => t.lang === this.game.lang)
            if (!traduction) {
                return wordGroup.text
            }
            return traduction.text
        },

        async changeWordGroup() {
            if (!this.isCreator) return //cant update game

            let errors = {}
            if (!this.newWordGroupUuid) {
                errors.newWordGroupUuid = this.$t("validationErrors.wordGroup")
            }
            this.errors = errors
            if (Object.keys(errors).length > 0) {
                return
            }

            //same word group, fake a good update
            if (this.currentWordGroup && this.newWordGroupUuid === this.currentWordGroup.uuid) {
                this.cancelNewWordGroupUuid()
                return
            }
            //we will update with web socket
            await ajax.put(`/user/live-games/${this.game.uuid}`, {
                wordGroupUuid: this.newWordGroupUuid
            })

            this.cancelNewWordGroupUuid()
        },

        async updateGameWordsCount() {
            if (!this.isCreator) return //cant update game

            let errors = {}
            if (!this.wordsCount || this.wordsCount < 1) {
                errors.wordsCount = this.$t("validationErrors.wordsCount")
            }
            this.errors = errors
            if (Object.keys(errors).length > 0) {
                return
            }
            //we will update with web socket
            await ajax.put(`/user/live-games/${this.game.uuid}`, {
                wordsCount: this.wordsCount
            })

            this.cancelWordsCount()
        },
        async updateGameMaxSecondsToAnswer() {
            if (!this.isCreator) return //cant update game

            let errors = {}
            if (!this.maxSecondsToAnswer || this.maxSecondsToAnswer < 5 || this.maxSecondsToAnswer > 40) {
                errors.maxSecondsToAnswer = this.$t("validationErrors.maxSecondsToAnswer")
            }
            this.errors = errors
            if (Object.keys(errors).length > 0) {
                return
            }
            //we will update with web socket
            await ajax.put(`/user/live-games/${this.game.uuid}`, {
                maxSecondsToAnswer: this.maxSecondsToAnswer
            })

            this.cancelMaxSecondsToAnswer()
        },
        async updateGameCode() {
            if (!this.isCreator) return //cant update game

            let errors = {}
            if (!this.newGameCode || this.newGameCode.length !== 6) {
                errors.code = this.$t("validationErrors.gameCode")
            }
            this.errors = errors
            if (Object.keys(errors).length > 0) {
                return
            }
            //we will update with web socket
            await ajax.put(`/user/live-games/${this.game.uuid}`, {
                code: this.newGameCode
            })

            this.cancelNewGameCode()
        },
        async updateLickGameOptions() {
            let url = `/user/live-games/${this.game.uuid}`
            if (this.$store.getters.isStudentLogin) {
                url = `/student/live-games/${this.game.uuid}`
            }

            //make sure to reset
            if (this.optionsLickGame.type === "time") {
                this.optionsLickGame.money = null//reset tim since its money limit
            }
            if (this.optionsLickGame.type === "money") {
                this.optionsLickGame.min = null//reset tim since its money limit
            }

            await ajax.put(url, {
                options: this.optionsLickGame
            })

            this.showOptionsLickGame = false
        },
        copyGameLink() {
            window.navigator.clipboard.writeText(
                `${window.location.origin}/anonymous/live/${this.game.uuid}/${this.game.gameType.slug}`
            )
        },
        isStudent(student) {
            if (!this.$store.getters.isStudentLogin || !student) return
            return student.id === this.$store.state.auth.student.id
        },
        stopCountdownTimer() {
            //clear
            clearInterval(this.timerIntervalId)
        },
        onClickStart() {
            //dont start if no student
            if (this.gameData.studentGames.length === 0) return
            this.$emit("start")
        },
        startCountdownTimer() {
            if (!this.gameData) return

            this.stopCountdownTimer()

            this.timerIntervalId = setInterval(() => {
                this.secondsBeforeStart = msToTime(this.gameData.startedAtMs - moment().utc().valueOf()).seconds

                //we hit zero
                if (this.secondsBeforeStart <= 0) {
                    this.stopCountdownTimer()
                }
            }, 1000) //every second
        }
    }
}
</script>
