<template>
    <div class="register">
        <Loader v-if="isLoading" />
        <form
            @submit.prevent="submit"
            v-else>
            <div class="md:grid gap-3 grid-cols-2">
                <div class="c__input">
                    <label
                        for="firstName"
                        class="">
                        {{ $t("form.firstName") }}
                    </label>

                    <input
                        v-model="firstName"
                        id="firstName"
                        type="text"
                        required
                        name="firstName" />

                    <p
                        class="error"
                        v-if="errors.firstName">
                        {{ errors.firstName }}
                    </p>
                </div>
                <div class="c__input">
                    <label
                        for="lastName"
                        class="">
                        {{ $t("form.lastName") }}
                    </label>

                    <input
                        v-model="lastName"
                        id="lastName"
                        type="text"
                        required
                        name="lastName" />

                    <p
                        class="error"
                        v-if="errors.lastName">
                        {{ errors.lastName }}
                    </p>
                </div>
            </div>

            <div class="c__input">
                <label
                    for="email"
                    class="">
                    {{ $t("form.email") }}
                </label>

                <input
                    v-model="email"
                    id="email"
                    type="email"
                    required
                    name="email" />

                <p
                    class="error"
                    v-if="errors.email">
                    {{ errors.email }}
                </p>
            </div>
            <div class="md:grid gap-3 grid-cols-2">
                <div class="c__input">
                    <label
                        for="password"
                        class="">
                        {{ $t("form.createPwd") }}
                    </label>

                    <input
                        v-model="password"
                        id="password"
                        type="password"
                        required
                        name="password" />

                    <p
                        class="error"
                        v-if="errors.password">
                        {{ errors.password }}
                    </p>
                </div>
                <div class="c__input">
                    <label
                        for="confirmPassword"
                        class="">
                        {{ $t("form.confirmPwd") }}
                    </label>

                    <input
                        v-model="confirmPassword"
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        autocomplete="new-password"
                        minlength="6"
                        required="required" />

                    <p
                        class="error"
                        v-if="errors.confirmPassword">
                        {{ errors.confirmPassword }}
                    </p>
                </div>
            </div>
            <div class="mt-4">
                <label
                    for="acceptTerms"
                    class="-small">
                    <input
                        v-model="acceptTerms"
                        type="checkbox"
                        name="acceptTerms"
                        id="acceptTerms" />

                    {{ $t("form.acceptTerms1") }}
                    <router-link
                        class="text-purple"
                        to="/terms"
                        target="_blank"
                        >{{ $t("form.acceptTerms2") }}</router-link
                    >
                    {{ $t("form.acceptTerms3") }}
                    <router-link
                        class="text-purple"
                        to="/privacy"
                        target="_blank"
                        >{{ $t("form.acceptTerms4") }}</router-link
                    >
                    {{ $t("form.acceptTerms5") }}
                </label>
                <p
                    class="error"
                    v-if="errors.acceptTerms">
                    {{ errors.acceptTerms }}
                </p>
            </div>

            <div class="mt-4">
                <label
                    for="acceptAds"
                    class="-small">
                    <input
                        v-model="acceptAds"
                        type="checkbox"
                        name="acceptAds"
                        id="acceptAds" />
                    {{ $t("form.acceptAds") }}
                </label>
                <p
                    class="error"
                    v-if="errors.acceptAds">
                    {{ errors.acceptAds }}
                </p>
            </div>

            <div class="flex flex-row justify-center items-center mt-6">
                <CustomButton type="submit">
                    {{ $t("auth.btnCreateAccount") }}
                </CustomButton>
            </div>
        </form>
    </div>
</template>
<script>
import * as types from "store/types"
import CustomButton from "components/globals/CustomButton"
import Loader from "components/Loader"

export default {
    components: { Loader, CustomButton },
    name: "RegisterUserForm",
    props: {
        role: {
            type: String
        }
    },
    data() {
        return {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            acceptAds: false,
            acceptTerms: false,
            isLoading: false,
            errors: {}
        }
    },
    mounted() {
        if (this.$route.query.email) {
            this.email = this.$route.query.email
        }
        if (this.$route.query.name) {
            let split = this.$route.query.name.split(" ")
            this.firstName = split[0]
            this.lastName = this.$route.query.name.replace(this.firstName + " ", "")
        }
    },
    methods: {
        submit() {
            this.errors = {}
            //validations

            if (!this.acceptTerms) {
                this.errors.acceptTerms = this.$t("validationErrors.acceptTerms")
            }

            if (Object.keys(this.errors).length > 0) {
                return
            }

            this.isLoading = true
            this.$store
                .dispatch(types.USER_REGISTER, {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    password: this.password,
                    confirmPassword: this.confirmPassword,
                    role: this.role,
                    acceptAds: this.acceptAds,
                    acceptTerms: this.acceptTerms
                })
                .then((res) => {
                    this.isLoading = false
                    this.$router.push("/")
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        }
    }
}
</script>
