<template>
    <div
        v-if="isLoading"
        class="min-w-screen min-h-screen flex flex-col justify-center items-center">
        <loader></loader>
    </div>
    <flash v-if="!isLoading"></flash>
    <main v-if="!isLoading">
        <router-view />
    </main>
    <!-- <div class="h-12">
     <div class="w-full flex flex-col justify-center items-center">
       <flash v-if="!isLoading"></flash>
       <footer class="bg-gray-200 h-12 w-full flex flex-row justify-center items-center relative z-2">
         <p class="text-center text-black text-14">
           © {{ year }} Adimo Inc. Tous droits réservés.
         </p>
       </footer>
     </div>
   </div> -->
    <ClosedSocketModal
        v-if="showClosedSocketModal"
        @close="showClosedSocketModal = false" />
    <FinishedSessionModal
        v-if="$store.state.auth.finishedSession"
        @close="closeFinishedSession" />
    <div id="modal" />

    <div class="hidden">
        <audio
            id="global-sounds__good-sound"
            class="hidden"
            preload="none">
            <source
                :src="goodAnswerSound"
                type="audio/mpeg" />
        </audio>
        <audio
            id="global-sounds__bad-sound"
            class="hidden"
            preload="none">
            <source
                :src="badAnswerSound"
                type="audio/mpeg" />
        </audio>
    </div>
</template>
<script>
import * as types from "store/types"
import Loader from "components/Loader"
import Flash from "components/Flash"
import ajax from "config/ajax"
import { LANGS, SOCKET_EVENTS } from "@globals/constants"
import ClosedSocketModal from "components/modals/global/ClosedSocketModal"
import FinishedSessionModal from "components/modals/global/FinishedSessionModal"
import goodAnswerSound from "assets/sounds/sound_good.mp3"
import badAnswerSound from "assets/sounds/sound_missed.mp3"
import i18n, { selectLang } from "config/i18n"

export default {
    components: {
        FinishedSessionModal,
        ClosedSocketModal,
        Flash,
        Loader
    },
    data() {
        return {
            goodAnswerSound,
            badAnswerSound,

            isLoading: true,
            showClosedSocketModal: false,
            year: new Date().getFullYear()
        }
    },
    watch: {
        "$store.getters.isStudentLogin"() {
            this.$nextTick(() => {
                this.setClosingSocket()
            })
        },
        "$store.getters.isUserLogin"() {
            this.$nextTick(() => {
                this.setClosingSocket()
            })
        }
    },
    created() {
        this.checkAuthData()
        this.setClosingSocket()

        ajax.post("/global/stats/visit")
    },
    beforeUnmount() {
        this.closeClosingSocket()
    },
    methods: {
        closeFinishedSession() {
            this.$store.commit(types.SET_FINISHED_SESSION, false)
        },
        closeClosingSocket() {
            if (this.$store.getters.isStudentLogin && this.$store.state.auth.studentSocket) {
                this.$store.state.auth.studentSocket.off(SOCKET_EVENTS.CLOSING_SOCKET, this.onCloseSocket)
            }
            if (this.$store.getters.isUserLogin && this.$store.state.auth.userSocket) {
                this.$store.state.auth.userSocket.off(SOCKET_EVENTS.CLOSING_SOCKET, this.onCloseSocket)
            }
        },
        setClosingSocket() {
            this.closeClosingSocket()

            if (this.$store.getters.isStudentLogin && this.$store.state.auth.studentSocket) {
                this.$store.state.auth.studentSocket.on(SOCKET_EVENTS.CLOSING_SOCKET, this.onCloseSocket)
            }
            if (this.$store.getters.isUserLogin && this.$store.state.auth.userSocket) {
                this.$store.state.auth.userSocket.on(SOCKET_EVENTS.CLOSING_SOCKET, this.onCloseSocket)
            }
        },
        onCloseSocket() {
            this.showClosedSocketModal = true
            this.$router.push("/auth/logout")
        },
        async checkAuthData() {
            try {
                if (this.$store.state.auth.studentJwt) {
                    this.$store.commit(types.STUDENT_JWT, this.$store.state.auth.studentJwt)

                    await this.$store.dispatch(types.GET_STUDENT)
                    if (this.$router.currentRoute.name === "login") {
                        this.$router.replace("/student")
                    }
                } else if (this.$store.state.auth.userJwt) {
                    this.$store.commit(types.USER_JWT, this.$store.state.auth.userJwt)

                    await this.$store.dispatch(types.GET_USER)
                    if (this.$router.currentRoute.name === "login") {
                        this.$router.replace("/user")
                    }
                } else if (this.$store.state.auth.anonymousJwt) {
                    this.$store.commit(types.ANONYMOUS_JWT, this.$store.state.auth.anonymousJwt)

                    await this.$store.dispatch(types.GET_ANONYMOUS)
                    if (this.$router.currentRoute.name === "login") {
                        this.$router.replace("/anonymous")
                    }
                }
            } catch (e) {
                this.$store.commit(types.LOGOUT)
                this.$router.replace("/auth/login")
            }

            //load new lang
            if (i18n.global.locale !== LANGS.FR) {
                //make sure we load JSON
                await selectLang(i18n.global.locale, true)
            }

            this.isLoading = false
        }
    }
}
</script>
