<template>
    <div class="flex flex-row justify-center items-stretch h-full max-h-full">
        <div class="w-full md:w-2/3 md:pr-3 flex flex-col justify-start items-start">
            <template v-if="!everyStudentsHasAnswer && studentAnswered">
                <div class="flex flex-col justify-center items-center h-full w-full rounded bg-white">
                    <h1>
                        {{ $t("game.waitingPlayers") }}
                    </h1>
                </div>
            </template>
            <template v-else-if="currentSection">
                <div
                    class="w-full rounded bg-white p-2 flex flex-row justify-center items-center"
                    v-if="sentence">
                    <h2 class="text-center">
                        {{ sentence.text }}
                    </h2>
                    <button
                        type="button"
                        class="appearance-none cursor-pointer ml-4"
                        @click.prevent.stop="playSound">
                        <Card
                            rounded
                            color="yellow">
                            <div class="p-1">
                                <Icons
                                    icon="game-type-hear"
                                    class="min-w-8 w-8 h-8 block text-white" />
                            </div>
                        </Card>
                    </button>
                </div>
                <div class="mt-6 grid grid-cols-2 grid-rows-2 gap-6 w-full h-0 flex-1">
                    <button
                        v-for="answer in currentSection.answers"
                        :key="answer.id"
                        type="button"
                        class="appearance-none cursor-pointer rounded relative p-2 flex flex-col justify-center items-center text-center h2"
                        :class="{
                            'bg-red text-white': answer.wasWrongAnswer,
                            'bg-green text-white': answer.wasGoodAnswer,
                            'bg-white text-purple': !answer.wasGoodAnswer && !answer.wasWrongAnswer
                        }"
                        @click.prevent="sendAnswer(answer.uuid)">
                        <img
                            v-if="answer.imageUrl"
                            class="opacity-20 inset-0 z-1 w-full h-full absolute block object-contain object-center"
                            :src="answer.imageUrl" />
                        <span
                            class="relative z-2"
                            v-if="getGameTraduction(answer)">
                            {{ getGameTraduction(answer).text }}
                        </span>
                    </button>
                </div>
            </template>
        </div>
        <div class="hidden md:block w-1/3 md:pl-3">
            <div class="rounded bg-white p-4 h-full max-h-full flex flex-col justify-start items-start">
                <div class="h-0 flex-1 w-full">
                    <CurrentGameScoreboard :game-data="gameData" />
                </div>

                <div class="w-full flex flex-row justify-start items-center">
                    <h3 class="mr-2">{{ $t("game.timeLeft") }}:</h3>
                    <GameTimer
                        class="block"
                        :timer="{ seconds: timerSeconds, minutes: 0 }"
                        tag="h2" />
                </div>
            </div>
        </div>

        <audio
            ref="audioTag"
            class="hidden"
            autoplay="false"
            autostart="0">
            <source
                v-if="sentence"
                :src="sentence.soundUrl"
                :type="`${sentence.soundUrl.includes('.wav') ? 'audio/wav' : 'audio/mpeg'}`" />
        </audio>
    </div>
</template>
<script>
import Card from "components/cards/Card"
import Icons from "components/globals/Icons"
import CurrentGameScoreboard from "components/game/CurentGameScoreboard"
import GameTimer from "components/game/GameTimer"
import { GAME_TYPES } from "@globals/constants"

const DEFAULT_LIMIT = 15 //15 seconds

//todo since this game type take alot of info, maybe create a wrapper ?
export default {
    name: "GameContest",
    components: { GameTimer, CurrentGameScoreboard, Icons, Card },
    props: {
        game: Object,
        gameData: Object,
        wordGroup: Object,
        studentGameData: Object,
        currentSection: Object
    },
    emits: ["answer"],
    data() {
        return {
            timerSeconds: 0,
            studentAnswered: false
        }
    },
    watch: {
        currentSection(nextValue) {
            //make sure we play sound only on new word value
            if (!nextValue.isReal) {
                this.studentAnswered = false
                return
            }

            this.$nextTick(async () => {
                this.timerSeconds = this.gameData.maxSecondsToAnswer //reset to max
                this.playSound()
                this.startTimer()
                this.startTotalTimer()
            })
        },
        timerSeconds(nextValue) {
            if (nextValue === 0) {
                this.stopTimer() //stop timer on 0

                //if the student didnt answer yet, answer for him !
                if (this.currentSection && !this.studentAnswered) {
                    let incorrectAnswers = this.currentSection.answers.filter((w) => w.id !== this.word.id)
                    this.sendAnswer(incorrectAnswers[0].uuid)
                }
            }
        }
    },
    mounted() {
        //play on init
        this.timerSeconds = this.gameData ? this.gameData.maxSecondsToAnswer : DEFAULT_LIMIT
        this.playSound()
        this.startTimer()
        this.startTotalTimer()
    },
    beforeUnmount() {
        this.stopTimer()
        this.stopTotalTimer();
    },
    computed: {
        everyStudentsHasAnswer() {
            if (!this.game) return false
            if (this.game.gameType.slug !== GAME_TYPES.CONTEST) return false
            if (!this.currentSection) return false
            return this.gameData.studentGames.every(
                (sG) => sG.sectionsDoneCount >= this.studentGameData.sectionsDoneCount
            )
        },
        word() {
            if (!this.currentSection) return null
            if (this.currentSection.words.length === 0) return null
            return this.currentSection.words[0] //backend will have place the right one in the first position
        },
        traduction() {
            if (!this.currentSection) return null
            return this.getGameTraduction(this.word)
        },
        sentence() {
            if (!this.traduction) return null
            if (this.traduction.sentences.length === 0) return null
            return this.traduction.sentences[0] //backend will have place the right one in the first position
        }
    },
    methods: {
        async playSound() {
            if (!this.$refs.audioTag) return
            this.$refs.audioTag.autoplay = false

            try {
                await this.$refs.audioTag.pause()
                await this.$refs.audioTag.load()
                await this.$refs.audioTag.play()
                this.$refs.audioTag.playbackRate = 0.8
            } catch (e) {}
        },
        getGameTraduction(word) {
            if (!word || !this.game) return null
            return word.traductions.find((t) => t.lang === this.game.lang)
        },
        stopTimer() {
            //clear
            clearInterval(this.timerIntervalId)
        },
        startTimer() {
            this.stopTimer()

            this.timerIntervalId = setInterval(() => {
                this.timerSeconds -= 1
            }, 1000)
        },
        sendAnswer(answerUuid) {
            if (!this.currentSection.isReal) return

            this.studentAnswered = true
            this.$emit("answer", {
                wordUuid: this.word.uuid,
                answeredWordUuid: answerUuid
            })
        }
    }
}
</script>
