<template>
    <div class="w-full h-full max-h-full">
        <Loader v-if="isLoading || localIsLoading" />
        <div
            class="flex flex-col justify-start items-start w-full h-full max-h-full"
            v-else>
            <div>
                <h2>
                    {{ $t("billing.teachersInfo") }}
                </h2>
            </div>

            <simplebar class="w-full h-0 flex-1">
                <div class="mt-6">
                    <div
                        class="w-full flex flex-row justify-start items-center py-2 space-x-1 border-b border-purple-10 mt-6"
                        v-if="$store.state.userData.paymentPlan.teacherPlans.length > 0">
                        <div class="w-1/4">
                            <p class="-smallest font-medium text-purple">
                                {{ $t("billing.price") }}
                            </p>
                        </div>
                        <div class="w-1/4">
                            <p class="-smallest font-medium text-purple">
                                {{ $t("billing.teacherInfo") }}
                            </p>
                        </div>
                        <div class="w-1/4">
                            <p class="-smallest font-medium text-purple">
                                {{ $t("billing.period") }}
                            </p>
                        </div>
                        <div class="w-1/4">
                            <p class="-smallest font-medium text-purple">
                                {{ $t("billing.status") }}
                            </p>
                        </div>
                    </div>
                    <div v-if="$store.state.userData.paymentPlan">
                        <div
                            class="flex flex-row justify-start items-center space-x-1 w-full py-2"
                            v-for="(teacherPlan, idx) in $store.state.userData.paymentPlan.teacherPlans"
                            :key="teacherPlan.id"
                            :class="{
                                'border-t border-purple-10': idx !== 0
                            }">
                            <div class="w-1/4">
                                <p
                                    class="-small text-purple"
                                    v-if="teacherPlan.subscription">
                                    ${{ teacherPlan.subscription.price }} {{ $t("billing.priceInfo") }}
                                </p>
                                <p
                                    class="-small text-purple"
                                    v-else-if="teacherPlan.plan && teacherPlan.license">
                                    ${{ teacherPlan.plan.price }} {{ $t("billing.priceInfo") }}
                                </p>
                                <p
                                    class="-small text-purple"
                                    v-else>
                                    {{ $t("billing.subscriptionCanceled") }}
                                </p>
                            </div>
                            <div class="w-1/4">
                                <p class="-small text-purple">
                                    {{ teacherPlan.user.name }}
                                </p>
                                <p class="-small text-purple">
                                    {{ teacherPlan.user.email }}
                                </p>
                                <p class="-small text-purple">
                                    {{ $t("billing.studentsCount") }}: {{ teacherPlan.user.studentsCount }}
                                </p>
                            </div>
                            <div class="w-1/4">
                                <p
                                    class="-small text-purple"
                                    v-if="teacherPlan.license">
                                    {{ moment(teacherPlan.license.startedAt).format("LL") }}<br />
                                    {{ moment(teacherPlan.license.finishedAt).format("LL") }}
                                </p>
                                <p
                                    class="-small text-purple"
                                    v-else>
                                    {{ $t("billing.subscriptionCanceled") }}
                                </p>
                            </div>
                            <div class="w-1/4">
                                <p
                                    class="-small text-purple"
                                    v-if="teacherPlan.license">
                                    <span>
                                        {{ $t(`global.braintreeStatus.active`) }}
                                    </span>
                                    <span
                                        class="inline-block text-yellow underline cursor-pointer ml-2"
                                        @click.prevent="confirmCancelTeacherSubscription(teacherPlan)">
                                        {{ $t("billing.btnCancelSubscription") }}
                                    </span>
                                </p>
                                <p
                                    class="-small text-purple"
                                    v-else>
                                    <span>
                                        {{ $t("billing.subscriptionCanceled") }}
                                    </span>
                                    <span
                                        class="inline-block text-yellow underline cursor-pointer ml-2"
                                        v-if="paymentData"
                                        @click.prevent="createTeacherSubscription(teacherPlan.user)">
                                        {{ $t("billing.btnActiveSubscription") }}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <p
                            class="text-purple -small mt-2"
                            v-if="$store.state.userData.paymentPlan.teacherPlans.length === 0">
                            {{ $t("list.noTeachers") }}
                        </p>
                    </div>
                </div>

                <div class="py-6">
                    <div class="inline-block">
                        <CustomButton
                            @click.prevent="setCreateTeacherModal(true)"
                            :disabled="!paymentData">
                            {{ $t("licenses.addTeacher") }}
                        </CustomButton>
                        <p
                            v-if="!paymentData"
                            class="error">
                            {{ $t("validationErrors.paymentMethod") }}
                        </p>
                    </div>
                </div>
            </simplebar>
        </div>

        <CreateTeacherModal
            v-if="showCreateTeacherModal"
            @close="showCreateTeacherModal = false"
            @success="onCreateSuccessTeacher" />
        <CancelTeacherSubscriptionModal
            :teacher="cancelTeacherSubscriptionUser"
            @cancel="cancelTeacherSubscription"
            @close="showCancelTeacherSubscriptionModal = false"
            v-if="showCancelTeacherSubscriptionModal" />
        <!--   <BuyLicensesModal
               v-if="showBuyLicensesModal"
               @close="setBuyLicensesModal(false)"
               @success="$emit('changed')" />
           <EditLicenseGroupModal
               v-if="showEditLicenseGroupModal"
               @close="showEditLicenseGroupModal = false"
               :license-group="selectedLicenseGroup" /> -->
    </div>
</template>

<script>
import Loader from "components/Loader"
import Simplebar from "components/globals/Simplebar"
import CustomButton from "components/globals/CustomButton"
import CreateTeacherModal from "components/modals/user/CreateTeacherModal.vue"
import { tryAsyncFunc } from "@globals/helpers/async"
import ajax from "config/ajax"
import CancelTeacherSubscriptionModal from "components/modals/user/CancelTeacherSubscriptionModal.vue"
import * as types from "store/types"

export default {
    name: "SchoolManagerSubscriptions",
    components: { CancelTeacherSubscriptionModal, CreateTeacherModal, CustomButton, Simplebar, Loader },
    emits: ["changed"],
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localIsLoading: false,
            showCreateTeacherModal: false,

            cancelTeacherSubscriptionUser: null,
            showCancelTeacherSubscriptionModal: false
        }
    },
    computed: {
        paymentData() {
            return this.$store.state.userData.paymentPlan && this.$store.state.userData.paymentPlan.paymentData
        }
    },
    methods: {
        setCreateTeacherModal(value) {
            if (value && !this.paymentData) return
            this.showCreateTeacherModal = value
        },
        confirmCancelTeacherSubscription(teacherPlan) {
            this.cancelTeacherSubscriptionUser = teacherPlan.user
            this.showCancelTeacherSubscriptionModal = true
        },
        async cancelTeacherSubscription() {
            this.localIsLoading = true
            this.showCancelTeacherSubscriptionModal = false

            const [res, err] = await tryAsyncFunc(ajax.post)(
                `/user/teachers/${this.cancelTeacherSubscriptionUser.uuid}/subscription/cancel`
            )

            this.$store.commit(types.USER_DATA.SET_FETCH, { key: "paymentPlan", value: false })
            this.$store.commit(types.USER_DATA.SET_FETCH, { key: "payments", value: false })
            this.localIsLoading = false

            this.$emit("changed")
        },
        async createTeacherSubscription(user) {
            this.localIsLoading = true
            const [res, err] = await tryAsyncFunc(ajax.post)(`/user/teachers/${user.uuid}/subscription/create`)

            this.$store.commit(types.USER_DATA.SET_FETCH, { key: "paymentPlan", value: false })
            this.$store.commit(types.USER_DATA.SET_FETCH, { key: "payments", value: false })
            this.localIsLoading = false

            this.$emit("changed")
        },
        onCreateSuccessTeacher() {
            this.showCreateTeacherModal = false
            this.$store.commit(types.USER_DATA.SET_FETCH, { key: "paymentPlan", value: false })
            this.$store.commit(types.USER_DATA.SET_FETCH, { key: "payments", value: false })
            this.$emit("changed")
        }
    }
}
</script>
