<template>
    <modal
        :show="true"
        @close="onClose">
        <h1>
            {{ $t("modal.anonymousAccount.title") }}
        </h1>
        <p class="text-purple -small my-4">
            {{ $t("modal.anonymousAccount.description") }}
        </p>

        <form
            class="mt-6"
            @submit.prevent="submit">
            <div>
                <div class="c__input">
                    <label>
                        {{ $t("form.name") }}
                    </label>
                    <input
                        type="text"
                        name="name"
                        min="4"
                        max="191"
                        v-model="anonymous.name"
                        required />
                    <p
                        class="error"
                        v-if="errors.name">
                        {{ errors.name }}
                    </p>
                </div>
                <div class="md:grid grid-cols-2 gap-6">
                    <div class="c__input">
                        <label>
                            {{ $t("form.lang") }}
                        </label>
                        <div class="c__select">
                            <select
                                v-model="anonymous.lang"
                                name="lang"
                                class="form-select">
                                <option
                                    v-for="lang in Object.values(AVAILABLE_LANGS)"
                                    :key="lang"
                                    :value="lang">
                                    {{ $t(`global.langs.${lang}`) }}
                                </option>
                            </select>
                        </div>
                        <p
                            class="error"
                            v-if="errors.lang">
                            {{ errors.lang }}
                        </p>
                    </div>
                <!--    <div class="c__input">
                        <label>
                            {{ $t("form.learningLang") }}
                        </label>
                        <div class="c__select">
                            <select
                                v-model="anonymous.learningLang"
                                name="learningLang"
                                class="form-select">
                                <option
                                    v-for="lang in Object.values(AVAILABLE_LEARNING_LANGS)"
                                    :key="lang"
                                    :value="lang">
                                    {{ $t(`global.langs.${lang}`) }}
                                </option>
                            </select>
                        </div>
                        <p
                            class="error"
                            v-if="errors.learningLang">
                            {{ errors.learningLang }}
                        </p>
                    </div> -->
                </div>
            </div>
            <div class="mt-6">
                <CustomButton type="submit">
                    {{ $t("form.btnStart") }}
                </CustomButton>
            </div>
        </form>
    </modal>
</template>
<script>
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"
import * as types from "store/types"
import { AVAILABLE_LANGS, AVAILABLE_LEARNING_LANGS } from "@globals/constants"
import { getCurrentLang, selectLang } from "config/i18n"

export default {
    components: { CustomButton, Modal },
    name: "CreateAnonymousUserModal",
    data() {
        return {
            AVAILABLE_LEARNING_LANGS,
            AVAILABLE_LANGS,

            anonymous: {
                name: "",
                lang: "",
                learningLang: ""
            },
            errors: {}
        }
    },
    created() {
        this.$store.commit(types.LOGOUT) //make sure to logout before

        let currentLang = getCurrentLang()
        this.anonymous.lang = currentLang
        this.anonymous.learningLang = currentLang
    },
    watch: {
        "anonymous.lang"(lang) {
            selectLang(lang)
            this.anonymous.learningLang = lang//set to learning too
        }
    },
    methods: {
        onClose() {
            this.$router.push("/auth/register")
        },
        async submit() {
            let errors = {}

            if (this.anonymous.name === "") {
                errors.name = this.$t("validationErrors.name")
            }

            if (Object.keys(errors).length > 0) {
                return
            }

            await this.$store.dispatch(types.ANONYMOUS_LOGIN, this.anonymous)

            //go to next url if needed
            if (this.$route.query && this.$route.query.next_url) {
                this.$router.replace(this.$route.query.next_url)
            }

            this.showModal = false
        }
    }
}
</script>
