<template>
    <div class="flex flex-col justify-start items-start w-full h-full">
        <h2 class="mb-6">
            {{ $t("game.settings") }}
        </h2>
        <simplebar class="h-0 flex-1 w-full">
            <div class="w-full h-full">
                <h3>Fin de partie:</h3>
                <h4 class="mb-2 mt-12">
                    Temps limite:
                </h4>
                <div class="grid grid-cols-3 gap-6">
                    <button
                        type="button"
                        class=" text-center w-full py-6 border-2 flex flex-col justify-center items-center rounded-xl font-bold cursor-pointer shadow"
                        :class="{
                            'bg-white border-red text-red': modelValue.min !== 5,
                            'border-red bg-red text-white': modelValue.min === 5
                        }"
                        @click="onChangeMinutes(5)">
                        <span class="text-22">5</span>
                        <span class="text-11">min</span>
                    </button>
                    <button
                        type="button"
                        class=" text-center w-full py-6 border-2 flex flex-col justify-center items-center rounded-xl font-bold cursor-pointer shadow"
                        :class="{
                            'bg-white border-red text-red': modelValue.min !== 10,
                            'border-red bg-red text-white': modelValue.min === 10
                        }"
                        @click="onChangeMinutes(10)">
                        <span class="text-22">10</span>
                        <span class="text-11">min</span>
                    </button>
                    <button
                        type="button"
                        class=" text-center w-full py-6 border-2 flex flex-col justify-center items-center rounded-xl font-bold cursor-pointer shadow"
                        :class="{
                            'bg-white border-red text-red': modelValue.min !== 15,
                            'border-red bg-red text-white': modelValue.min === 15
                        }"
                        @click="onChangeMinutes(15)">
                        <span class="text-22">15</span>
                        <span class="text-11">min</span>
                    </button>
                </div>

                <h4 class="mb-2 mt-12">
                    Argent limite:
                </h4>
                <div class="grid grid-cols-3 gap-6">
                    <button
                        type="button"
                        class=" text-center w-full py-6 border-2 flex flex-col justify-center items-center rounded-xl font-bold cursor-pointer shadow"
                        :class="{
                            'bg-white border-red text-red': modelValue.money !== 100,
                            'border-red bg-red text-white': modelValue.money === 100
                        }"
                        @click="onChangeMoney(100)">
                        <span class="text-22">100</span>
                    </button>
                    <button
                        type="button"
                        class=" text-center w-full py-6 border-2 flex flex-col justify-center items-center rounded-xl font-bold cursor-pointer shadow"
                        :class="{
                            'bg-white border-red text-red': modelValue.money !== 150,
                            'border-red bg-red text-white': modelValue.money === 150
                        }"
                        @click="onChangeMoney(150)">
                        <span class="text-22">150</span>
                    </button>
                    <button
                        type="button"
                        class=" text-center w-full py-6 border-2 flex flex-col justify-center items-center rounded-xl font-bold cursor-pointer shadow"
                        :class="{
                            'bg-white border-red text-red': modelValue.money !== 200,
                            'border-red bg-red text-white': modelValue.money === 200
                        }"
                        @click="onChangeMoney(200)">
                        <span class="text-22">200</span>
                    </button>
                </div>
            </div>
        </simplebar>

    </div>
</template>

<script>
import Simplebar from "components/globals/Simplebar"

export default {
    name: "LickGameOptions",
    components: { Simplebar },
    emits: ["update:modelValue"],
    props: {
        modelValue: Object
    },
    methods: {
        onChangeMoney(money) {
            this.$emit("update:modelValue", {
                type: "money",
                min: null,
                money
            })
        },
        onChangeMinutes(min) {
            this.$emit("update:modelValue", {
                type: "time",
                money: null,
                min
            })
        }
    }
}
</script>
