<template>
    <StudentHeader class="mb-6">
        <h1 class="text-white">
            {{ $t("play.liveGame") }}
        </h1>
    </StudentHeader>

    <div class="xl:grid grid-cols-4 grid-rows-1 gap-6 w-full flex-1 h-0 max-h-full">
        <div class="col-span-3 flex flex-col justify-start items-start max-h-full">
            <simplebar class="scroll-container h-full max-h-full">
                <div class="pb-6 w-full">
                    <h2 class="pb-6 text-white">
                        {{ $t("play.joinLiveGame") }}
                    </h2>
                    <div class="inline-block pb-6">
                        <CustomButton @click.prevent="showJoinGameByCodeModal = true">
                            {{ $t("play.joinLiveGameWithCode") }}
                        </CustomButton>
                    </div>
                    <div class="grid grid-cols-1 gap-6">
                        <LiveGameCard
                            v-for="game in liveGames"
                            :key="game.id"
                            :game="game"
                            @join="joinLiveBattle(game.uuid)"
                            @remove="removeLiveBattle(game)" />
                    </div>
                </div>
                <h2 class="pb-6 text-white">
                    {{ $t("play.createLiveGame") }}
                </h2>
                <template v-if="types.length > 0">
                    <div class="mb-3 w-full">
                        <TabbedButtons
                            @click="selectedGroupType = $event"
                            :value="selectedGroupType"
                            value-key="key"
                            :options="types" />
                    </div>
                    <div
                        class="form inline-block"
                        v-if="selectedGroupType !== TYPES.RANDOM">
                        <div
                            class="c__input"
                            v-if="selectedGroupType === TYPES.LEAGUE">
                            <label class="text-white">
                                <span class="font-medium">{{ $t("play.league") }}</span>
                            </label>
                            <div class="c__select">
                                <select
                                    class="form-select"
                                    name="selectedLeagueUuid"
                                    v-model="selectedLeagueUuid">
                                    <option
                                        v-for="league in $store.state.studentData.leagues"
                                        :key="league.id"
                                        :value="league.uuid">
                                        {{ league.name }}
                                    </option>
                                </select>
                            </div>
                            <p
                                class="error"
                                v-if="errors.selectedLeagueUuid">
                                {{ errors.selectedLeagueUuid }}
                            </p>
                        </div>
                        <div
                            class="c__input"
                            v-if="selectedGroupType === TYPES.CLASSROOM">
                            <label class="text-white">
                                <span class="font-medium">{{ $t("play.classroom") }}</span>
                            </label>
                            <div class="c__select">
                                <select
                                    class="form-select"
                                    name="selectedClassroomUuid"
                                    v-model="selectedClassroomUuid">
                                    <option
                                        v-for="classroom in $store.state.studentData.classrooms"
                                        :key="classroom.id"
                                        :value="classroom.uuid">
                                        {{ classroom.title }}
                                    </option>
                                </select>
                            </div>
                            <p
                                class="error"
                                v-if="errors.selectedClassroomUuid">
                                {{ errors.selectedClassroomUuid }}
                            </p>
                        </div>
                    </div>
                </template>
                <div class="grid grid-cols-1 gap-6 pb-6 pt-3">
                    <WordGroupCard
                        v-for="wordGroup in $store.getters.sortedWordGroups"
                        :key="wordGroup.id"
                        :word-group="wordGroup"
                        @create="startLiveBattle"
                        version="live" />
                </div>
            </simplebar>
        </div>
        <div class="hidden xl:flex col-span-1 pb-6 flex-col justify-start items-start">
            <h2 class="my-6 text-white">
                {{ $t("scoreboard.lastHour") }}
            </h2>
            <Card class="h-full w-full">
                <div class="p-4">
                    <h2 class="mb-6">Top 10</h2>
                    <div
                        class="flex flex-col 2xl:flex-row justify-start 2xl:justify-between items-start 2xl:items-center w-full p-3 border-t border-purple-10"
                        v-for="(studentGame, idx) in $store.state.studentData.lastHourScoreboard"
                        :class="{
                            'bg-gray-100': studentGame.student.id === $store.state.auth.student.id,
                            'border-b': idx + 1 === $store.state.studentData.lastHourScoreboard.length
                        }"
                        :key="studentGame.id">
                        <div class="flex flex-row justify-start items-center">
                            <span class="block text-14 font-bold text-purple min-w-4">
                                {{ idx + 1 }}
                            </span>
                            <p class="text-purple px-2 break-all">
                                {{ studentGame.student.username }}
                            </p>
                        </div>
                        <div class="flex flex-row justify-start items-center space-x-1 pl-5 2xl:pl-0">
                            <div class="flex flex-row justify-start items-center space-x-1">
                                <Icons
                                    icon="badge"
                                    class="w-6 h-6 block text-purple" />
                                <span
                                    class="text-14 font-bold"
                                    :class="`${studentGame.poleCount > 1 ? 'text-purple' : 'text-purple-40'}`">
                                    {{ studentGame.poleCount }}
                                </span>
                            </div>
                            <div class="flex flex-row justify-start items-center space-x-1">
                                <Icons
                                    icon="podium"
                                    class="w-6 h-6 block text-purple" />
                                <span
                                    class="text-14 font-bold"
                                    :class="`${studentGame.podiumCount > 1 ? 'text-purple' : 'text-purple-40'}`">
                                    {{ studentGame.podiumCount }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <p
                        v-if="$store.state.studentData.lastHourScoreboard.length === 0"
                        class="-small text-purple">
                        {{ $t("scoreboard.noPlayersLastHour") }}
                    </p>
                </div>
            </Card>
        </div>
    </div>

    <JoinGameByCodeModal
        v-if="showJoinGameByCodeModal"
        @close="showJoinGameByCodeModal = false" />
</template>

<script>
import * as types from "store/types"
import Card from "components/cards/Card"
import CustomButton from "components/globals/CustomButton"
import StudentHeader from "components/globals/StudentHeader"
import WordGroupCard from "components/cards/WordGroupCard"
import Icons from "components/globals/Icons"
import ajax from "config/ajax"
import LiveGameCard from "components/cards/LiveGameCard"
import JoinGameByCodeModal from "components/modals/student/JoinGameByCodeModal"
import Simplebar from "components/globals/Simplebar"
import TabbedButtons from "components/globals/TabbedButtons.vue"

const TYPES = {
    RANDOM: "random",
    LEAGUE: "league",
    CLASSROOM: "classroom"
}

export default {
    name: "LiveBattle",
    components: {
        TabbedButtons,
        Icons,
        Simplebar,
        JoinGameByCodeModal,
        LiveGameCard,
        WordGroupCard,
        StudentHeader,
        CustomButton,
        Card
    },
    data() {
        return {
            TYPES,

            selectedGroupType: TYPES.RANDOM,
            // showLeagueModal: false,

            showJoinGameByCodeModal: false,

            selectedLeagueUuid: null,
            selectedClassroomUuid: null,

            errors: {},

            liveGames: []
        }
    },
    async created() {
        this.$store.dispatch(types.STUDENT_DATA.GET_WORD_GROUPS)
        this.$store.dispatch(types.STUDENT_DATA.GET_CLASSROOMS)
        this.$store.dispatch(types.STUDENT_DATA.GET_LEAGUES)
        this.$store.dispatch(types.STUDENT_DATA.GET_LAST_HOUR_LIVE_BATTLE_SCOREBOARD)

        let res = await ajax.get(`/student/live-games`)
        this.liveGames = res.games
    },
    watch: {
        selectedGroupType(nextValue) {
            //reset
            this.selectedLeagueUuid = null
            this.selectedClassroomUuid = null

            //autoselect first value
            if (nextValue === TYPES.LEAGUE && this.$store.state.studentData.leagues.length > 0) {
                this.selectedLeagueUuid = this.$store.state.studentData.leagues[0].uuid
            }
            if (nextValue === TYPES.CLASSROOM && this.$store.state.studentData.classrooms.length > 0) {
                this.selectedClassroomUuid = this.$store.state.studentData.classrooms[0].uuid
            }
        }
    },
    computed: {
        gameTypes() {
            return this.$store.state.studentData.global.gameTypes.filter((g) => g.live && g.acceptFakeStudents)
        },
        types() {
            //show nothing
            if (
                this.$store.state.studentData.classrooms.length === 0 &&
                this.$store.state.studentData.leagues.length === 0
            ) {
                this.selectedGroupType = TYPES.RANDOM
                return []
            }

            return [
                {
                    key: TYPES.RANDOM,
                    icon: "live-random",
                    label: this.$t("play.random")
                },
                ...(this.$store.state.studentData.leagues.length > 0
                    ? [
                          {
                              key: TYPES.LEAGUE,
                              icon: "live-badge",
                              label: this.$t("play.league")
                          }
                      ]
                    : []),
                ...(this.$store.state.studentData.classrooms.length > 0
                    ? [
                          {
                              key: TYPES.CLASSROOM,
                              icon: "live-classroom",
                              label: this.$t("play.classroom")
                          }
                      ]
                    : [])
            ]
        }
    },
    methods: {
        async joinLiveBattle(gameUuid) {
            let res = await ajax.post(`/student/live-games/${gameUuid}/join`)

            const { game } = res

            this.$router.push(`/student/live/${game.uuid}/${game.gameType.slug}`)
        },
        removeLiveBattle(game) {
            let idx = this.liveGames.findIndex((g) => g.id === game.id)
            if (idx === -1) return
            this.liveGames.splice(idx, 1)
        },
        async startLiveBattle({ gameTypeSlug, wordGroupUuid }) {
            let errors = {}

            if (this.selectedGroupType === TYPES.LEAGUE && !this.selectedLeagueUuid) {
                errors.selectedLeagueUuid = this.$t("validationErrors.league")
            }
            if (this.selectedGroupType === TYPES.CLASSROOM && !this.selectedClassroomUuid) {
                errors.selectedClassroomUuid = this.$t("validationErrors.classroom")
            }
            this.errors = errors

            //skip
            if (Object.keys(errors).length > 0) {
                return
            }

            const { game } = await ajax.post(`/student/live-games`, {
                gameTypeSlug: gameTypeSlug,
                wordGroupUuid: wordGroupUuid,

                //add league uuid if there
                ...(this.selectedLeagueUuid
                    ? {
                          leagueUuid: this.selectedLeagueUuid
                      }
                    : {}),
                ...(this.selectedLeagueUuid
                    ? {
                          leagueUuid: this.selectedLeagueUuid
                      }
                    : {})
            })

            this.$router.push(`/student/live/${game.uuid}/${gameTypeSlug}`)
        }
    }
}
</script>
