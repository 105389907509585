<template>
    <modal
        :show="true"
        @close="$emit('close')"
        :scrollable="false">
        <div class="flex flex-col justify-start items-start max-h-full h-full">
            <h2 class="mb-6">
                {{ $t("form.duplicateWordGroupFromCode") }}
            </h2>
            <div class="form w-full max-h-full h-full flex flex-col justify-start items-start overflow-y-hidden">

                <form
                    @submit.prevent="findWordGroup"
                    class="w-full mt-6">
                    <div class="c__input">
                        <label>
                            {{ $t("global.search") }}
                        </label>

                        <div class="w-full relative">
                            <input
                                :placeholder="$t('form.duplicateWordGroupFromCodeInput')"
                                type="text"
                                v-model="code"
                                name="search"
                                class="w-full"
                                @keyup.enter.prevent.stop="findWordGroup" />
                            <Icons
                                icon="search"
                                @click.prevent.stop="findWordGroup"
                                class="w-7 h-7 block cursor-pointer absolute top-1/2 -translate-y-1/2 transform right-2" />
                        </div>
                    </div>
                </form>

                <div class="w-full mt-12 flex flex-col justify-center items-center" v-if="wordGroup">
                    <SmallWordGroupCard :word-group="wordGroup" />



                    <CustomButton class="mt-4" @click.prevent="duplicateWordGroup">
                        Dupliquer dans votre liste de mot
                    </CustomButton>
                </div>
                <div v-else class="mt-12">
                    <p class="error">
                        Aucune liste de mot pour votre recherche
                    </p>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"
import * as types from "store/types"
import { tryAsyncFunc } from "@globals/helpers/async"
import ajax from "config/ajax"
import Icons from "components/globals/Icons.vue"
import SmallWordGroupCard from "components/cards/SmallWordGroupCard.vue"

export default {
    name: "DuplicateWordGroupFromCode",
    emits: ["close"],
    components: { SmallWordGroupCard, Icons, CustomButton, Modal },
    props: {
        listGroupId: Number
    },

    data() {
        return {
            code: "",
            wordGroup: null,

            errors: {}
        }
    },
    methods: {
        async findWordGroup() {
            this.wordGroup = null

            let code = this.code.replace("#", "")//make sure to remove #
            let [res, err] = await tryAsyncFunc(ajax.get)(`/user/word-groups/code/${code}`)

            if (err) {
                return
            }

            this.wordGroup = res.wordGroup
        },
        duplicateWordGroup() {
            if (!this.wordGroup) return

            this.$store.dispatch(types.USER_DATA.DUPLICATE_WORD_GROUP_BY_UUID, {
                listGroupId: this.listGroupId,
                wordGroupUuid: this.wordGroup.uuid
            })
            this.$emit("close")
        }
    }
}
</script>
