<template>
    <modal
        :show="true"
        @close="$emit('close')">
        <h2 v-if="isUser">
            {{ $t("modal.cancelTeacherSubscription.titleSelf") }}
        </h2>
        <h2 v-else>
            {{ $t("modal.cancelTeacherSubscription.title", { name: teacher.name }) }}
        </h2>

        <p
            class="my-4"
            v-if="isUser">
            {{ $t("modal.cancelTeacherSubscription.descriptionSelf") }}
        </p>
        <p
            class="my-4"
            v-else>
            {{ $t("modal.cancelTeacherSubscription.description") }}
        </p>

        <!--    <p class="my-4" v-if="isUser">
                {{ $t("modal.cancelTeacherSubscription.description2Self", {endDateStr}) }}
            </p>
            <p class="my-4" v-else>
                {{ $t("modal.cancelTeacherSubscription.description2", {endDateStr }) }}
            </p> -->

        <CustomButton @click.prevent="$emit('cancel')">
            {{ $t("modal.cancelTeacherSubscription.btn") }}
        </CustomButton>
    </modal>
</template>
<script>
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"

export default {
    name: "CancelTeacherSubscriptionModal",
    components: { CustomButton, Modal },
    emits: ["close", "cancel"],
    props: {
        teacher: Object
        // license: Object,
    },
    computed: {
        /* endDateStr(){
            return moment(this.license.finishedAt).utc().local().format("LL")
        }, */
        isUser() {
            return this.teacher.id === this.$store.state.auth.user.id
        }
    }
}
</script>
