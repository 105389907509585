<template>
    <div class="h-full flex flex-col justify-between items-center space-y-6">
        <div
            class="w-full h-1/2 md:flex flex-row justify-between items-center md:space-x-6 p-2 md:p-0 rounded bg-white md:bg-transparent">
            <div class="w-full md:w-1/2 h-1/2 md:h-full rounded relative">
                <div
                    v-if="currentSection"
                    class="w-full h-full rounded bg-white flex flex-col justify-center items-center"
                    :class="{
                        'bg-white': !currentSection.isWrongAnswer,
                        'bg-green': currentSection.isWrongAnswer
                    }">
                    <template v-if="!currentSection.isWrongAnswer">
                        <h3 class="mb-6">{{ $t("game.hearTheWord") }}</h3>
                        <button
                            type="button"
                            class="appearance-none cursor-pointer"
                            @click.prevent.stop="playSound">
                            <Card
                                rounded
                                color="yellow">
                                <div class="p-1">
                                    <Icons
                                        icon="game-type-hear"
                                        class="w-8 h-8 md:w-12 md:h-12 block text-white" />
                                </div>
                            </Card>
                        </button>
                        <audio
                            ref="audioTag"
                            class="hidden"
                            autoplay="false"
                            autostart="0">
                            <source
                                v-if="gameTraduction"
                                :src="gameTraduction.soundUrl"
                                :type="`${gameTraduction.soundUrl.includes('.wav') ? 'audio/wav' : 'audio/mpeg'}`" />
                        </audio>
                    </template>
                    <template v-else>
                        <h1 class="h-12 tracking-widest flex flex-row justify-center items-center uppercase text-white">
                            {{ gameTraduction.text }}
                        </h1>
                    </template>
                </div>
            </div>

            <div
                class="w-full md:w-1/2 h-1/2 md:h-full rounded pt-3 md:pt-0"
                :class="{
                    'bg-red': currentSection.isWrongAnswer,
                    'bg-green': currentSection.isCorrectAnswer,
                    'bg-white': !currentSection.isWrongAnswer && !currentSection.isCorrectAnswer
                }">
                <div class="w-full h-full flex flex-col justify-center items-center">
                    <h3
                        class="mb-6"
                        :class="{
                            'text-white': currentSection.isWrongAnswer || currentSection.isCorrectAnswer,
                            'text-purple': !currentSection.isWrongAnswer && !currentSection.isCorrectAnswer
                        }">
                        {{ $t("game.wordOfXLetters", { lettersCount: gameTraduction.text.length }) }}&nbsp;/&nbsp;
                        <span
                            v-for="(type, idx) in word.type"
                            :key="type">
                            {{ idx > 0 ? ", " : "" }}{{ $t(`global.wordTypes.${type}`) }}
                        </span>
                    </h3>
                    <h1 class="h-12 tracking-widest flex flex-row justify-center items-center">
                        <span
                            v-for="(letter, index) in currentAnswerLetters"
                            :key="index"
                            class="uppercase"
                            :class="{
                                'text-white': currentSection.isWrongAnswer || currentSection.isCorrectAnswer,
                                'text-purple': !currentSection.isWrongAnswer && !currentSection.isCorrectAnswer
                            }">
                            <template v-if="letter !== ' '">
                                {{ letter }}
                            </template>
                            <template v-else> &#9679;</template>
                        </span>
                        <template v-if="gameTraduction && gameTraduction.text.length - currentAnswerLetters.length > 0">
                            <span
                                v-for="(_, index) in gameTraduction.text.length - currentAnswerLetters.length"
                                :key="index"
                                class="black block bg-gray-150 h-6 md:h-10 w-6 md:w-10 mr-2 mb-2 rounded" />
                        </template>
                    </h1>
                    <div
                        class="w-3/4 mx-auto border-dashed border-t-2 h-2"
                        :class="{
                            'border-white': currentSection.isWrongAnswer || currentSection.isCorrectAnswer,
                            'border-black': !currentSection.isWrongAnswer && !currentSection.isCorrectAnswer
                        }"></div>
                </div>
            </div>
        </div>
        <div class="w-full h-1/2">
            <VirtualKeyboard
                accept-real-keys
                class="w-full h-full"
                @update:modelValue="onKeyboardPress"
                :model-value="currentAnswer"
                ref="keyboard" />
        </div>

        <div class="w-full flex flex-row justify-center items-center space-x-6">
            <button
                type="button"
                class="w-1/2 appearance-none cursor-pointer rounded py-6 flex flex-col justify-center items-center text-center bg-white text-purple h3"
                @click.prevent="skip">
                {{ $t("game.skipQuestion") }}
            </button>
            <button
                type="button"
                class="w-1/2 appearance-none cursor-pointer rounded py-6 flex flex-col justify-center items-center text-center h3"
                :class="{
                    'bg-gray-400 text-gray-200': lettersLeftCount > 0,
                    'bg-green text-white': lettersLeftCount === 0
                }"
                @click.prevent="sendAnswer">
                {{ $t("game.validate") }}
            </button>
        </div>
    </div>
</template>
<script>
import { FLAGS } from "services/countryFlags"
import VirtualKeyboard from "components/globals/VirtualKeyboard.vue"
import Card from "components/cards/Card.vue"
import Icons from "components/globals/Icons.vue"

export default {
    name: "GameWrite",
    components: { Icons, Card, VirtualKeyboard },
    props: {
        game: Object,
        wordGroup: Object,
        currentSection: Object
    },
    emits: ["answer"],
    data() {
        return {
            FLAGS,

            currentAnswer: ""
        }
    },
    watch: {
        currentSection(nextValue, prevValue) {
            //reset to empty arr when we changed section
            if (nextValue && prevValue) {
                if (prevValue.sectionIdx !== nextValue.sectionIdx) {
                    this.currentAnswer = ""
                }
            }

            if (nextValue.words.length === prevValue.words.length && !nextValue.isReal) return

            this.$nextTick(async () => {
                this.playSound()
            })

            this.currentAnswer = ""
        }
    },
    mounted() {
        this.playSound()
    },
    computed: {
        currentAnswerLetters() {
            return this.currentAnswer.split("")
        },
        word() {
            if (!this.currentSection) return null
            if (this.currentSection.words.length === 0) return null
            return this.currentSection.words[0]
        },
        gameTraduction() {
            if (!this.word || !this.game) return null
            return this.word.traductions.find((t) => t.lang === this.game.lang) || null
        },
        lettersLeftCount() {
            if (!this.gameTraduction) return 0
            return this.gameTraduction.text.length - this.currentAnswer.length
        }
    },
    methods: {
        async playSound() {
            if (!this.$refs.audioTag) return

            this.$refs.audioTag.autoplay = false
            try {
                await this.$refs.audioTag.load()
                await this.$refs.audioTag.play()
                this.$refs.audioTag.playbackRate = 0.8
            } catch (e) {}
        },
        onKeyboardPress(newValue) {
            //we are already at full length needed and we try to add keys
            if (
                this.currentAnswer.length === this.gameTraduction.text.length &&
                newValue.length > this.currentAnswer.length
            ) {
                return
            }
            this.currentAnswer = newValue
        },
        sendAnswer() {
            if (!this.currentSection.isReal) return
            if (!this.gameTraduction) return
            if (this.lettersLeftCount > 0) return
            let isCorrectAnswer = this.gameTraduction.text.toLowerCase() === this.currentAnswer.toLowerCase()

            this.$emit("answer", {
                wordUuid: this.word.uuid,
                answeredWordUuid: isCorrectAnswer ? this.word.uuid : null, //pass null for wrong answer
                inputText: this.currentAnswer
            })
        },
        skip() {
            if (!this.currentSection.isReal) return
            this.$emit("answer", {
                wordUuid: this.word.uuid,
                answeredWordUuid: null,
                skipQuestion: true
            })
        }
    }
}
</script>
