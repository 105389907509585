<template>
    <modal
        size="big"
        :show="true"
        :scrollable="false"
        @close="$emit('close')">
        <div
            class="w-full h-full grid grid-cols-1 md:grid-cols-2 gap-12"
            :class="{
                'md:grid-cols-2': !dictation,
                'md:grid-cols-3': !!dictation
            }">
            <div class="relative col-span-1">
                <h2 class="mb-2">
                    {{ $t("tools.dictation.title") }}
                </h2>
                <div
                    class="flex flex-row justify-center items-center absolute inset-0"
                    v-if="isLoading">
                    <Loader />
                </div>
                <form
                    :class="{
                        'opacity-50 pointer-events-none': isLoading
                    }"
                    @submit.prevent="generateDictation"
                    class="space-y-6">
                    <div class="c__input">
                        <label> {{ $t("tools.dictation.wordsCount") }} </label>
                        <div class="c__select">
                            <select
                                v-model.number="wordCount"
                                name="wordCount"
                                class="form-select">
                                <option
                                    v-for="_wordCount in WORDS_COUNT"
                                    :key="_wordCount"
                                    :value="_wordCount">
                                    ~ {{ _wordCount }} {{ $t("tools.dictation.words") }}
                                </option>
                            </select>
                        </div>
                        <p
                            class="error"
                            v-if="errors.wordCount">
                            {{ errors.wordCount }}
                        </p>
                    </div>
                    <div class="c__input">
                        <label>
                            {{ $t("form.classroom") }}
                        </label>
                        <label class="-smallest">
                            {{ $t("tools.dictation.classroomExplanation") }}
                        </label>
                        <div class="c__select">
                            <select
                                v-model.number="classroomId"
                                name="classroomId"
                                class="form-select">
                                <option :value="null">
                                    {{ $t("global.noneFr") }}
                                </option>
                                <option
                                    v-for="classroom in $store.state.userData.classrooms"
                                    :key="classroom.id"
                                    :value="classroom.id">
                                    {{ classroom.title }}
                                </option>
                            </select>
                        </div>
                        <p
                            class="error"
                            v-if="errors.classroomId">
                            {{ errors.classroomId }}
                        </p>
                    </div>

                    <div class="c__input">
                        <label>
                            {{ $t("form.extraWords") }}
                        </label>
                        <label class="-smallest">
                            {{ $t("tools.dictation.oneWordPerLine") }}
                        </label>
                        <textarea
                            style="
                                 {
                                    height: 200px;
                                }
                            "
                            :cols="8"
                            name="extraWords"
                            v-model="extraWordsText" />
                        <p
                            class="error"
                            v-if="errors.extraWords">
                            {{ errors.extraWords }}
                        </p>
                    </div>
                    <div>
                        <p class="-smallest error pb-3">
                            {{ $t("tools.dictation.warning") }}
                        </p>
                        <CustomButton type="submit">
                            {{ !!dictation ? $t("tools.dictation.regenerate") : $t("tools.dictation.create") }}
                        </CustomButton>
                    </div>
                </form>
            </div>
            <div
                class="flex flex-row justify-start items-stretch"
                :class="{
                    'col-span-1': !dictation,
                    'col-span-2': !!dictation
                }">
                <div
                    class="w-full flex flex-col justify-center items-center"
                    :class="{
                        'md:w-2/3': !!dictation
                    }">
                    <div class="w-full flex-1 rounded border border-purple p-3">
                        <p
                            class="-small text-purple"
                            v-if="!dictation">
                            {{ $t("tools.dictation.willBeGenerateHere") }}
                        </p>
                        <p
                            class="h-full text-purple"
                            v-html="finalDictationText"></p>
                    </div>
                    <div v-if="dictation">
                        <p class="mt-6 text-red font-medium -smallest">
                            {{ $t("tools.dictation.warningMes") }}
                        </p>
                    </div>
                    <div
                        class="flex flex-row justify-center items-center mt-6 space-x-3"
                        v-if="dictation">
                        <CustomButton
                            @click.prevent="copyDictation"
                            type="button">
                            {{ $t("tools.dictation.copy") }}
                        </CustomButton>
                        <!--
                        <CustomButton
                            @click.prevent="downloadDictation"
                            type="button">
                            Télécharger DOC
                        </CustomButton> -->
                    </div>
                </div>
                <div
                    class="md:w-1/3 flex flex-col justify-start items-center p-6"
                    v-if="dictation">
                    <h3>
                        {{ $t("tools.dictation.wordsUsed") }}
                    </h3>
                    <ul>
                        <li
                            v-for="word in wordsUsed"
                            :key="word">
                            {{ word }}
                        </li>
                    </ul>
                    <CustomButton
                        class="mt-6"
                        version="text"
                        @click.prevent="toggleWordsUsed"
                        type="button">
                        {{ showWordsUsed ? $t("tools.dictation.hideWords") : $t("tools.dictation.showWords") }}
                    </CustomButton>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import Modal from "components/globals/Modal.vue"
import { tryAsyncFunc } from "@globals/helpers/async"
import ajax from "config/ajax"
import CustomButton from "components/globals/CustomButton.vue"
import Loader from "components/Loader.vue"
import { replace } from "lodash-es"
import { createArray } from "@globals/functions"
import * as types from "store/types"
import EventBus, { EVENTS } from "@/eventbus"

export default {
    name: "ToolGenerateDictationModal",
    components: { Loader, CustomButton, Modal },
    emits: ["close"],
    data() {
        return {
            isLoading: false,
            studentIds: [],
            classroomId: null,
            wordIds: [],
            extraWordsText: "",
            wordCount: 100,

            WORDS_COUNT: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500],

            errors: {},

            dictation: "",
            showWordsUsed: true,
            wordsUsed: []
        }
    },
    created() {
        this.$store.dispatch(types.USER_DATA.GET_CLASSROOMS)
    },
    watch: {
        classroomId() {
            this.$nextTick(() => {
                this.$store.dispatch(types.USER_DATA.GET_STUDENTS_BY_CLASSROOM_UUID, this.classroom.uuid)
            })
        }
    },
    computed: {
        classroom() {
            return this.$store.state.userData.classrooms.find((c) => c.id === this.classroomId)
        },
        finalDictationText() {
            if (this.showWordsUsed) {
                return this.dictation
            }
            let text = this.dictation
            for (let wordUsed of this.wordsUsed) {
                let underscoreWithCorrectLength = createArray(wordUsed.length)
                    .map(() => "_")
                    .join("")
                text = replace(text, new RegExp(wordUsed, "g"), underscoreWithCorrectLength)
            }
            return text
        }
    },
    methods: {
        async generateDictation() {
            this.isLoading = true
            this.wordsUsed = []
            this.dictation = ""
            this.showWordsUsed = true
            let extraWords = this.extraWordsText.split("\n")
            extraWords = extraWords.filter((word) => !!word) //remove empty space

            const [data, err] = await tryAsyncFunc(ajax.request)({
                url: `/user/tools/generate-dictation`,
                method: "POST",
                data: {
                    studentIds: this.studentIds,
                    classroomId: this.classroomId,
                    wordIds: this.wordIds,
                    extraWords: extraWords
                },
                timeout: 2 * 60 * 1000 //2 minutes
            })
            this.isLoading = false

            if (err) {
                this.errors = err.errors || {}
                return
            }

            this.dictation = data.dictation
            this.wordsUsed = data.wordsUsed
        },
        downloadDictation() {
            //todo
        },
        copyDictation() {
            window.navigator.clipboard.writeText(this.finalDictationText)
            EventBus.emit(EVENTS.SHOW_FLASH, {
                title: "Dictée copiée avec succès",
                message: this.$t("tools.dictation.copyDictationSuccess"),
                type: "success"
            })
        },
        toggleWordsUsed() {
            this.showWordsUsed = !this.showWordsUsed
        }
    }
}
</script>
