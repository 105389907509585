<template>
    <modal
        :show="true"
        @close="$emit('close')">
        <div class="flex flex-col justify-center items-center space-y-6">
            <h2>
                {{ $t("modal.needLicense.title") }}
            </h2>

            <CustomButton to="/user/billing"> {{ $t("modal.needLicense.btn") }} </CustomButton>
        </div>
    </modal>
</template>

<script>
import Modal from "components/globals/Modal.vue"
import CustomButton from "components/globals/CustomButton.vue"

export default {
    name: "NeedLicenseModal",
    emnits: ["close"],
    components: { CustomButton, Modal }
}
</script>
