<template>
    <div class="c__input">
        <label type="">
            {{ $t("form.classroomTitle") }}
        </label>
        <input
            type="text"
            name="title"
            v-model="classroom.title" />
        <p
            class="error"
            v-if="errors.title">
            {{ errors.title }}
        </p>
    </div>
    <div class="md:grid grid-cols-2 gap-6">
        <div class="c__input">
            <label>
                {{ $t("form.grade") }}
            </label>
            <div class="c__select">
                <select
                    v-model.number="classroom.grade"
                    name="grade"
                    class="form-select">
                    <option
                        value=""
                        selected>
                        Sélectionner
                    </option>
                    <option
                        v-for="grade in Object.values(GRADES)"
                        :key="grade"
                        :value="grade">
                        {{ grade }}
                    </option>
                </select>
            </div>
            <p
                class="error"
                v-if="errors.grade">
                {{ errors.grade }}
            </p>
        </div>
        <div class="c__input">
            <label>
                {{ $t("form.classroomLang") }}
            </label>
            <div class="c__select">
                <select
                    v-model="classroom.lang"
                    name="lang"
                    class="form-select">
                    <option
                        v-for="lang in Object.values(AVAILABLE_LEARNING_LANGS)"
                        :key="lang"
                        :value="lang">
                        {{ $t(`global.langs.${lang}`) }}
                    </option>
                </select>
            </div>
            <p
                class="error"
                v-if="errors.lang">
                {{ errors.lang }}
            </p>
        </div>
    </div>

    <div class="c__input">
        <label>
            {{ $t("form.connectedListGroup") }}
        </label>
        <div class="c__select">
            <select
                v-model.number="classroom.listGroupId"
                name="listGroupId"
                class="form-select"
                value="">
                <option
                    value=""
                    selected>
                    {{ $t("global.nothing") }}
                </option>
                <option
                    v-for="listGroup in $store.state.userData.listGroups"
                    :key="listGroup.id"
                    :value="listGroup.id">
                    {{ listGroup.title }}
                </option>
            </select>
        </div>
        <p
            class="error"
            v-if="errors.listGroupId">
            {{ errors.listGroupId }}
        </p>
    </div>
    <p
        v-if="!classroom.listGroupId"
        class="text-error-red -small py-2">
        {{ $t("form.allListWordGroupWillBeShown") }}
    </p>
</template>

<script>
import { AVAILABLE_LEARNING_LANGS, DIFFICULTY, GRADES } from "@globals/constants"
import { getCurrentLang } from "config/i18n"

export default {
    name: "ClassroomForm",
    props: {
        classroom: Object,
        errors: Object
    },
    data() {
        return {
            DIFFICULTY,
            GRADES,
            AVAILABLE_LEARNING_LANGS
        }
    },
    created() {
        this.classroom.lang = getCurrentLang()
    },
    methods: {
        validate() {
            let errors = {}

            if (!this.classroom.title) {
                errors.title = this.$t("validationErrors.title")
            }
            if (this.classroom.title.length < 4) {
                errors.title = this.$t("validationErrors.titleLength")
            }
            return errors
        }
    }
}
</script>
