<template>
    <UserHeader class="mb-6">
        <div>
            <h1>{{ $t("play.title") }}</h1>
            <h2 v-if="liveGames.length === 0" class="pt-3">
                {{ $t("play.createLiveGame") }}
            </h2>
        </div>


        <template
            v-slot:end
            v-if="$store.state.auth.user.isTeacher">
            <ClassroomDropdown />
        </template>
    </UserHeader>

    <simplebar class="scroll-container">
        <div
            v-if="liveGames.length > 0"
            class="pb-12">
            <h2 class="pb-6">
                {{ $t("play.joinLiveGame") }}
            </h2>
            <div class="grid grid-cols-1 gap-6">
                <LiveGameCard
                    v-for="game in liveGames"
                    :key="game.id"
                    :game="game"
                    @join="joinLiveBattle(game)"
                    @remove="removeLiveBattle(game)" />
            </div>
        </div>

        <div class="">
            <h2 class="pb-6" v-if="liveGames.length > 0">
                {{ $t("play.createLiveGame") }}
            </h2>
            <div
                class="w-full"
                v-if="$store.state.auth.user.isTeacher">
                <TabbedButtons
                    size="small"
                    @click="onClickType"
                    :value="selectedWordGroupType"
                    value-key="key"
                    :options="types" />
            </div>
        </div>
        <div class="pt-3" v-if="selectedWordGroupType === WORD_GROUP_TYPES.LIST">
            <div class="w-full">
                <TabbedButtons
                    size="small"
                    @click="onClickWordGroupGrade"
                    :value="selectedWordGroupGrade"
                    :options="wordGroupGrades" />
            </div>
        </div>

        <!--   <div
            class="flex flex-row justify-start items-center space-x-3 mb-6 w-full"
            v-if="$store.state.auth.user.isTeacher">
            <BadgeButton
                v-for="type in types"
                :key="type.key"
                @click.prevent="onClickType(type)"
                :icon="type.icon"
                :label="type.label"
                :active="selectedWordGroupType === type.key" />
        </div> -->
        <div
            class="grid grid-cols-1 gap-6 py-6"
            v-if="filteredWordGroups.length > 0">
            <WordGroupCard
                class="cursor-pointer"
                v-for="wordGroup in filteredWordGroups"
                :key="wordGroup.id"
                :word-group="wordGroup"
                version="live"
                @create="createGame"
                @click.prevent="goToWordGroup(wordGroup.uuid)" />
        </div>
        <div v-else class="pt-6">
            <p class="-small text-purple">
                {{ $t("play.noCustomList") }}
            </p>
            <div class="inline-block">
                <CustomButton
                    v-if="currentClassroom"
                    version="text"
                    :to="`/user/students?classroomUuid=${currentClassroom.uuid}`">
                    {{ $t("play.ensureCustomListIsConnected") }}
                </CustomButton>
            </div>
        </div>
    </simplebar>
</template>

<script>
import * as types from "store/types"
import WordGroupCard from "components/cards/WordGroupCard"
import Simplebar from "components/globals/Simplebar"
import ajax from "config/ajax"
import LiveGameCard from "components/cards/LiveGameCard"
import ClassroomDropdown from "components/globals/ClassroomDropdown"
import UserHeader from "components/globals/UserHeader"
import { WORD_GROUP_TYPES } from "@globals/constants"
import CustomButton from "components/globals/CustomButton.vue"
import TabbedButtons from "components/globals/TabbedButtons.vue"

export default {
    name: "UserPlay",
    components: {
        TabbedButtons,
        CustomButton,
        UserHeader,
        ClassroomDropdown,
        LiveGameCard,
        Simplebar,
        WordGroupCard
    },
    data() {
        return {
            WORD_GROUP_TYPES,
            liveGames: [],
            switchCount: 0,

            selectedWordGroupType: null,
            selectedWordGroupGrade: 1,//default to 1 so we dont show all

            types: [
                {
                    key: WORD_GROUP_TYPES.CUSTOM,
                    icon: "list",
                    label: this.$t("play.typeCustom")
                },
                {
                    key: WORD_GROUP_TYPES.LIST,
                    icon: "list",
                    label: this.$t("play.typeList")
                }
            ]
        }
    },
    async created() {
        if (this.$store.state.auth.user.isSchoolManager) {
            this.$router.replace("/user/billing")
            return
        }

        this.$store.dispatch(types.USER_DATA.GET_WORD_GROUPS)
        this.$store.dispatch(types.USER_DATA.GET_CLASSROOMS)
        this.$store.dispatch(types.USER_DATA.GET_LIST_GROUPS)

        if (this.$store.state.auth.user.isTeacher) {
            this.selectedWordGroupType = WORD_GROUP_TYPES.CUSTOM
        }
        if (this.$store.state.auth.user.isParent) {
            this.selectedWordGroupType = WORD_GROUP_TYPES.LIST
        }

        let res = await ajax.get(`/user/live-games`)
        this.liveGames = res.games
    },
    watch: {
        filteredWordGroups(nextValue) {
            //if we have no word groups and we in custom mode, and a teacher, go back to list
            if (
                this.switchCount === 0 &&
                nextValue.length === 0 &&
                this.$store.state.auth.user.isTeacher &&
                this.selectedWordGroupType === WORD_GROUP_TYPES.CUSTOM
            ) {
                this.selectedWordGroupType = WORD_GROUP_TYPES.LIST
            }
        },
        currentClassroom(nextValue) {
            if (!nextValue) {
                this.selectedWordGroupGrade = 1
                return
            }
            this.selectedWordGroupGrade = nextValue.grade ? nextValue.grade : 1
        }
    },
    computed: {
        currentClassroom() {
            return (
                this.$store.state.userData.classrooms.find(
                    (c) => c.uuid === this.$store.state.userData.currentClassroomUuid
                ) || null
            )
        },
        currentListGroup() {
            if (!this.currentClassroom) return null
            return (
                this.$store.state.userData.listGroups.find((lG) => lG.id === this.currentClassroom.listGroupId) || null
            )
        },
        filteredWordGroups() {
            if (this.selectedWordGroupType === WORD_GROUP_TYPES.CUSTOM) {
                return this.$store.state.userData.wordGroups.filter(
                    (wG) =>
                        wG.type === this.selectedWordGroupType &&
                        this.currentListGroup &&
                        wG.listGroupId === this.currentListGroup.id
                )
            }

            //filter by group and if classroom, filter this grade
            return this.$store.state.userData.wordGroups.filter((wG) => {
                return wG.type === this.selectedWordGroupType && wG.grade === this.selectedWordGroupGrade
            })
        },
        wordGroupGrades() {
            return this.$store.state.userData.wordGroups.reduce((acc, wordGroup) => {
                if (!wordGroup.grade) return acc

                if (acc.some(w => w.value === wordGroup.grade)) {
                    return acc
                }
                let suffix = wordGroup.grade === 1 ? "re" : "e"
                acc.push({
                    label: `${wordGroup.grade}${suffix} ${this.$t("list.year")}`,
                    value: wordGroup.grade
                })
                return acc
            }, [])
        }
    },
    methods: {
        onClickType(typeKey) {
            this.switchCount++
            this.selectedWordGroupType = typeKey
        },
        onClickWordGroupGrade(grade) {
            this.selectedWordGroupGrade = grade
        },
        joinLiveBattle(game) {
            this.$router.replace(`/user/live/${game.uuid}/${game.gameType.slug}`)
        },
        removeLiveBattle(game) {
            let idx = this.liveGames.findIndex((g) => g.id === game.id)
            if (idx === -1) return
            this.liveGames.splice(idx, 1)
        },
        goToWordGroup(uuid) {
            this.$router.push(`/user/play/${uuid}`)
        },
        async createGame({ gameTypeSlug, wordGroupUuid }) {
            let res = await ajax.post("/user/live-games", {
                classroomUuid: this.$store.state.userData.currentClassroomUuid,
                wordGroupUuid: wordGroupUuid,
                gameTypeSlug: gameTypeSlug
            })

            let { success, game } = res

            if (!success) {
                //todo handle error
                return
            }

            this.$router.replace(`/user/live/${game.uuid}/${gameTypeSlug}`)
        }
    }
}
</script>
