<template>
    <StudentHeader class="mb-6">
        <h1 class="text-white">
            {{ $t("list.title") }}
        </h1>
    </StudentHeader>

    <simplebar class="scroll-container">
        <div class="grid grid-cols-1 gap-6 pb-6">
            <WordGroupCard
                class="cursor-pointer"
                v-for="wordGroup in $store.getters.sortedWordGroups"
                :key="wordGroup.id"
                :word-group="wordGroup"
                @click.prevent="goToWordGroup(wordGroup.uuid)" />
        </div>
    </simplebar>
</template>

<script>
import * as types from "store/types"
import StudentHeader from "components/globals/StudentHeader"
import WordGroupCard from "components/cards/WordGroupCard"
import Simplebar from "components/globals/Simplebar"

export default {
    name: "List",
    components: { Simplebar, WordGroupCard, StudentHeader },
    data() {
        return {}
    },
    created() {
        this.$store.dispatch(types.STUDENT_DATA.GET_WORD_GROUPS)
    },
    methods: {
        goToWordGroup(uuid) {
            this.$router.push(`/student/list/${uuid}`)
        }
    }
}
</script>
