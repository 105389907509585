<template>
    <div
        v-if="$store.getters.isStudentLogin || $store.getters.isUserLogin || $store.getters.isAnonymousLogin"
        :class="{
            'student-layout': $store.getters.isStudentLogin,
            'user-layout': $store.getters.isUserLogin,
            'anonymous-layout': $store.getters.isAnonymousLogin
        }">
        <div class="flex flex-row justify-start items-stretch w-full max-h-full relative z-1 h-full lg:pr-6">
            <div
                v-if="showMobileMenu"
                class="inset-0 fixed z-3 bg-black opacity-50"
                @click.prevent="closeMobileMenu" />
            <div
                class="fixed z-10 left-6 top-6 bottom-6 lg:inset-0 lg:static lg:block w-4/5 xs:w-2/3 md:w-1/3 lg:w-auto lg:min-w-56 xl:min-w-72 lg:mt-6 lg:ml-6 lg:mb-6"
                v-if="!isInGame"
                :class="{
                    hidden: !showMobileMenu
                }">
                <Card class="h-full">
                    <simplebar class="p-2 xl:p-4 overflow-y-auto h-full max-h-full">
                        <div class="flex flex-col justify-between items-start h-full">
                            <div class="w-full">
                                <div class="flex flex-row justify-between lg:justify-start items-center w-full">
                                    <div class="block lg:hidden">
                                        <CircleButton
                                            @click.prevent="closeMobileMenu"
                                            icon="cross" />
                                    </div>
                                    <router-link
                                        :to="homeLink"
                                        class="lg:w-2/3 max-w-36 inline-block">
                                        <img
                                            class="w-full h-auto block"
                                            :src="logo"
                                            alt="Adimo logo" />
                                        <p class="font-semibold mt-1 ml-12 -small">
                                            Beta
                                        </p>
                                    </router-link>
                                </div>

                                <div class="flex flex-col justify-start items-start mt-6">
                                    <div
                                        v-for="item in menu"
                                        class="w-full"
                                        :key="item.name">
                                        <div
                                            class="w-full"
                                            v-if="!item.hidden">
                                            <MenuItem
                                                v-if="!item.showPopup"
                                                @click="closeMobileMenu"
                                                :item="item" />
                                            <Popper
                                                v-else
                                                warning
                                                :offset-y="-10"
                                                placement="right"
                                                :content="item.popupLabel">
                                                <MenuItem
                                                    @click="closeMobileMenu"
                                                    :item="item" />
                                            </Popper>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="$store.getters.isAnonymousLogin"
                                    class="my-6">
                                    <CustomButton
                                        @click.prevent="createAccount"
                                        class="w-full">
                                        {{ $t("auth.btnCreateAccount") }}
                                    </CustomButton>
                                </div>
                            </div>
                            <div class="flex flex-col justify-start items-start mt-4 w-full">
                                <MenuItem
                                    v-for="item in bottomMenu"
                                    :key="item.name"
                                    :item="item" />
                            </div>
                        </div>
                    </simplebar>
                </Card>
            </div>
            <div class="px-2 lg:pl-6 w-full">
                <div
                    :class="{
                        'student-page': $store.getters.isStudentLogin,
                        'user-page': $store.getters.isUserLogin,
                        'anonymous-page': $store.getters.isAnonymousLogin
                    }">
                    <router-view />
                </div>
            </div>
        </div>
        <div class="absolute w-full inset-0 z-0">
            <img
                alt="Adimo asset"
                :src="bgImage"
                :srcset="`${bgMobileImage} 768w, ${bgImage}`"
                class="w-full block h-full object-cover object-bottom" />
        </div>
    </div>

    <CreateAnonymousUserModal v-if="showCreateAnonymousUserModal" />
    <NoGameLeftAnonymousModal v-if="showCreateAccountModal" />
    <NewGameInvitation v-if="$store.getters.isStudentLogin" />
    <NoLicenseModal v-if="$store.getters.isStudentLogin" />
</template>
<script>
import Card from "components/cards/Card"
import logo from "@globals/assets/logos/logo.svg"
import * as types from "store/types"
import bgDesktop01 from "assets/products/backgrounds/bg-desktop-01.jpg"
/*import bgDesktop02 from "assets/products/backgrounds/bg-desktop-02.jpg"
import bgDesktop03 from "assets/products/backgrounds/bg-desktop-03.jpg"
import bgDesktop04 from "assets/products/backgrounds/bg-desktop-04.jpg"
import bgDesktop05 from "assets/products/backgrounds/bg-desktop-05.jpg"*/
import bgMobile01 from "assets/products/backgrounds/bg-mobile-01.jpg"
/*import bgMobile02 from "assets/products/backgrounds/bg-mobile-02.jpg"
import bgMobile03 from "assets/products/backgrounds/bg-mobile-03.jpg"
import bgMobile04 from "assets/products/backgrounds/bg-mobile-04.jpg"
import bgMobile05 from "assets/products/backgrounds/bg-mobile-05.jpg"*/
import Simplebar from "components/globals/Simplebar"
import { SOCKET_EVENTS } from "@globals/constants"
import CustomButton from "components/globals/CustomButton"
import CreateAnonymousUserModal from "components/modals/anonymous/CreateAnonymousUserModal"
import NoGameLeftAnonymousModal from "components/modals/anonymous/NoGameLeftAnonymousModal"
import ajax from "config/ajax"
import EventBus, { EVENTS } from "@/eventbus"
import CircleButton from "components/globals/CircleButton"
import MenuItem from "components/globals/MenuItem"
import NoLicenseModal from "components/modals/student/NoLicenseModal"
import moment from "moment"
import NewGameInvitation from "components/modals/student/NewGameInvitation"
import { sleep } from "@globals/functions"
import Popper from "components/globals/Popper.vue"

export default {
    name: "AppLayout",
    components: {
        Popper,
        NewGameInvitation,
        NoLicenseModal,
        MenuItem,
        CircleButton,
        NoGameLeftAnonymousModal,
        CreateAnonymousUserModal,
        CustomButton,
        Simplebar,
        Card
    },
    data() {
        return {
            logo,

            showMobileMenu: false
        }
    },
    watch: {
        $route() {
            this.closeMobileMenu()
        },
        isConnected(nextValue) {
            if (nextValue) {
                this.initAuthData()
            } else {
                this.finishAuthData()
            }
        }
    },
    created() {
        this.initAuthData()
        EventBus.on(EVENTS.OPEN_MOBILE_MENU, this.openMobileMenu)
    },
    beforeUnmount() {
        EventBus.off(EVENTS.OPEN_MOBILE_MENU, this.openMobileMenu)
        this.finishAuthData()
    },
    computed: {
        bottomMenu() {
            let list = []
            if (this.$store.getters.isUserLogin) {
                list.push(
                    {
                        icon: "help",
                        name: this.$t("help.title"),
                        to: "https://adimo.app/zone-entraide#enseignant"
                    }
                )
            }

            list.push({
                icon: "logout",
                name: this.$t("global.logout"),
                to: "/auth/logout",
                textActive: true
            })
            return list
        },
        showCreateAnonymousUserModal() {
            return this.$route.name.startsWith("anonymous") && !this.$store.getters.isAnonymousLogin
        },
        isConnected() {
            return (
                this.$store.getters.isStudentLogin ||
                this.$store.getters.isAnonymousLogin ||
                this.$store.getters.isUserLogin
            )
        },
        bgImage() {
            if (!this.$store.getters.isStudentLogin) return bgDesktop01
            let product = this.$store.state.studentData.allProducts.find(
                (p) => p.id === this.$store.state.auth.student.mainBackgroundId
            )
            if (!product) {
                return bgDesktop01
            }

            switch (product.code) {
                case "01":
                    return bgDesktop01
                /*  case "02":
                    return bgDesktop02
                case "03":
                    return bgDesktop03
                case "04":
                    return bgDesktop04
                case "05":
                    return bgDesktop05 */
                default:
                    return bgDesktop01
            }
            return bgDesktop01
        },
        bgMobileImage() {
            if (!this.$store.getters.isStudentLogin) return bgMobile01

            let product = this.$store.state.studentData.allProducts.find(
                (p) => p.id === this.$store.state.auth.student.mainBackgroundId
            )
            if (!product) {
                return bgMobile01
            }

            switch (product.code) {
                case "01":
                    return bgMobile01
                /*  case "02":
                    return bgMobile02
                case "03":
                    return bgMobile03
                case "04":
                    return bgMobile04
                case "05":
                    return bgMobile05 */
                default:
                    return bgMobile01
            }
            return bgMobile01
        },
        hasNewNotification() {
            if (!this.$store.getters.isStudentLogin) return false
            if (this.$store.getters.isAnonymousLogin) return false
            let now = moment()
            //show bell if not seen notifications and if new notifications in the last 1 minute
            return this.$store.state.studentData.notifications.some(
                (n) => !n.seen || moment(now).diff(moment(n.createdAt), "minutes") < 1
            )
        },
        homeLink() {
            if (this.$store.getters.isStudentLogin) {
                return "/student"
            }
            if (this.$store.getters.isUserLogin) {
                return "/user"
            }
            if (this.$store.getters.isAnonymousLogin) {
                return "/anonymous"
            }
            return "/"
        },
        studentMenu() {
            return [
                {
                    icon: "home",
                    name: this.$t("home.title"),
                    exact: true,
                    to: "/student"
                },
                {
                    icon: "list",
                    name: this.$t("list.title"),
                    to: "/student/list"
                },
                {
                    icon: "flag",
                    name: this.$t("play.liveGame"),
                    to: "/student/live-battle"
                },
                {
                    icon: "users",
                    name: this.$t("friends.title"),
                    to: "/student/friends"
                },
                {
                    icon: "shop",
                    name: this.$t("shop.title"),
                    to: "/student/shop"
                },
                {
                    icon: "box",
                    name: this.$t("inventory.title"),
                    to: "/student/inventory"
                },
                {
                    icon: "badge",
                    name: this.$t("scoreboard.title"),
                    to: "/student/scoreboard",
                    //hide the scoreboard for user in classroom, teacher dont want student to compare themself
                    hidden: !!this.$store.state.auth.student?.teacherId
                },
                {
                    icon: "progress",
                    name: this.$t("stats.title"),
                    to: "/student/progress"
                },
                {
                    icon: "bell",
                    name: this.$t("notifications.title"),
                    to: "/student/notifications",
                    badge: this.hasNewNotification
                },
                {
                    icon: "gear",
                    name: this.$t("settings.simpleTitle"),
                    to: "/student/settings"
                }
            ]
        },
        schoolManagerMenu() {
            return [
                {
                    icon: "billing",
                    name: this.$t("licenses.title"),
                    to: "/user/billing"
                },
                {
                    icon: "gear",
                    name: this.$t("settings.simpleTitle"),
                    to: "/user/settings"
                }
            ]
        },
        teacherMenu() {
            return [
                {
                    icon: "home",
                    name: this.$t("play.title"),
                    exact: true,
                    to: "/user"
                },
                {
                    icon: "list",
                    name: this.$t("list.titleCustom"),
                    to: "/user/list",
                    showPopup: !this.$store.state.auth.user.licenseId,
                    popupLabel: this.$t("billing.needLicensePopup")
                },
                {
                    icon: "users",
                    name: this.$t("students.title"),
                    to: "/user/students",
                    showPopup: !this.$store.state.auth.user.licenseId,
                    popupLabel: this.$t("billing.needLicensePopup")
                },
                {
                    icon: "gear",
                    name: this.$t("tools.title"),
                    to: "/user/tools",
                    showPopup: !this.$store.state.auth.user.licenseId,
                    popupLabel: this.$t("billing.needLicensePopup")
                },
                {
                    icon: "billing",
                    name: this.$t("licenses.title"),
                    to: "/user/billing"
                },
                {
                    icon: "gear",
                    name: this.$t("settings.simpleTitle"),
                    to: "/user/settings"
                }
            ]
        },
        parentMenu() {
            return [
                {
                    icon: "home",
                    name: this.$t("play.title"),
                    exact: true,
                    to: "/user"
                },
                {
                    icon: "users",
                    name: this.$t("students.title"),
                    to: "/user/students"
                },
                {
                    icon: "billing",
                    name: this.$t("billing.title"),
                    to: "/user/billing"
                },
                {
                    icon: "gear",
                    name: this.$t("settings.simpleTitle"),
                    to: "/user/settings"
                }
            ]
        },
        anonymousMenu() {
            return [
                {
                    icon: "list",
                    name: this.$t("play.title"),
                    exact: true,
                    to: "/anonymous"
                }
            ]
        },
        menu() {
            if (this.$store.getters.isStudentLogin) {
                return this.studentMenu
            }
            if (this.$store.getters.isUserLogin) {
                if (this.$store.state.auth.user.isSchoolManager) {
                    return this.schoolManagerMenu
                }
                if (this.$store.state.auth.user.isTeacher) {
                    return this.teacherMenu
                }
                if (this.$store.state.auth.user.isParent) {
                    return this.parentMenu
                }
                return []
            }
            if (this.$store.getters.isAnonymousLogin) {
                return this.anonymousMenu
            }
            return []
        },
        isInGame() {
            return !!this.$route.meta.inGame
        },
        showCreateAccountModal() {
            if (!this.$store.getters.isAnonymousLogin) return false
            return this.$store.state.anonymousData.gameLeft <= 0
        }
    },
    methods: {
        finishAuthData() {
            if (this.$store.getters.isStudentLogin) {
                this.$store.state.auth.studentSocket.off(
                    SOCKET_EVENTS.NEW_NOTIFICATION,
                    this.socketStudentNewNotification
                )
                this.$store.state.auth.studentSocket.off(SOCKET_EVENTS.JOIN_LIVE_GAME, this.joinStudentLiveGame)
            }
            if (this.$store.getters.isAnonymousLogin) {
                this.$store.state.auth.anonymousSocket.off(SOCKET_EVENTS.JOIN_LIVE_GAME, this.joinAnonymousLiveGame)
            }
        },
        async initAuthData() {
            if (this.$store.getters.isAnonymousLogin) {
                //for stats
                ajax.post("/anonymous/stats/connected-visit")
                this.$store.state.auth.anonymousSocket.on(SOCKET_EVENTS.JOIN_LIVE_GAME, this.joinAnonymousLiveGame)
            }

            if (this.$store.getters.isStudentLogin) {
                this.$store.dispatch(types.STUDENT_DATA.GET_GLOBALS)
                this.$store.dispatch(types.STUDENT_DATA.GET_USED_PRODUCTS)
                this.$store.dispatch(types.STUDENT_DATA.GET_NOTIFICATIONS)
                this.$store.dispatch(
                    types.STUDENT_DATA.GET_PRODUCT_BY_ID,
                    this.$store.state.auth.student.mainBackgroundId
                )

                //for stats
                ajax.post("/student/stats/visit")

                //add socket events
                this.$store.state.auth.studentSocket.on(
                    SOCKET_EVENTS.NEW_NOTIFICATION,
                    this.socketStudentNewNotification
                )

                this.$store.state.auth.studentSocket.on(SOCKET_EVENTS.JOIN_LIVE_GAME, this.joinStudentLiveGame)
            }
            if (this.$store.getters.isUserLogin) {
                this.$store.dispatch(types.USER_DATA.GET_GLOBALS)

                //for stats
                ajax.post("/user/stats/visit")
            }
        },
        openMobileMenu() {
            this.showMobileMenu = true
        },
        closeMobileMenu() {
            this.showMobileMenu = false
        },
        createAccount() {
            ajax.post("/anonymous/stats/conversion")
            this.$router.push("/auth/register")
        },
        async joinStudentLiveGame(game) {
            this.$router.push(`/student`)
            await sleep(300)
            this.$router.push(`/student/live/${game.uuid}/${game.gameType.slug}`)
        },
        async joinAnonymousLiveGame(game) {
            this.$router.push(`/anonymous`)
            await sleep(300)
            this.$router.push(`/anonymous/live/${game.uuid}/${game.gameType.slug}`)
        },
        socketStudentNewNotification(notification) {
            this.$store.commit(types.STUDENT_DATA.GET_NOTIFICATION_BY_UUID, notification)
        }
    }
}
</script>
