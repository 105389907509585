import { createApp } from "vue"
import App from "./App.vue"
import router from "./config/router"
import store from "./store"
import "./config/ajax"
import i18n from "./config/i18n"
import "./assets/sass/main.scss"
import addMixins from "@globals/vue/mixins"
import addDirectives from "@globals/vue/directives"
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"

const app = createApp(App)

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        app,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["gilcode.com", "adimo.app", /^\//]
            })
        ],
        tracesSampleRate: 0.05
    })
}

app.use(router)
app.use(store)
app.use(i18n)

addMixins(app)
addDirectives(app)

app.mount("#app")
