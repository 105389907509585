<template>
    <div class="keyboard bg-white rounded p-2 md:p-3 grid grid-cols-1 grid-rows-3 gap-3 md:gap-6">
        <div class="flex flex-row justify-start items-center space-x-1 md:space-x-3 | first-row">
            <button
                v-for="btn in firstRow"
                :key="btn"
                type="button"
                @click.prevent="onBtnClick(btn)"
                :class="[
                    BTN_CLASS,
                    disabledLetters.includes(btn) ? '-disabled' : '',
                    hideLetters.includes(btn) ? '-hide' : '',
                    currentLetterHover === btn ? '-hover' : '',
                    accentRow.includes(btn) ? '-accent' : ''
                ]">
                {{ btn }}
            </button>
        </div>
        <div class="flex flex-row justify-start items-center space-x-1 md:space-x-3 md:ml-2 | second-row">
            <button
                v-for="btn in secondRow"
                :key="btn"
                type="button"
                @click.prevent="onBtnClick(btn)"
                :class="[
                    BTN_CLASS,
                    disabledLetters.includes(btn) ? '-disabled' : '',
                    hideLetters.includes(btn) ? '-hide' : '',
                    currentLetterHover === btn ? '-hover' : '',
                    accentRow.includes(btn) ? '-accent' : ''
                ]">
                {{ btn }}
            </button>
        </div>
        <div class="flex flex-row justify-start items-center space-x-1 md:space-x-3 md:ml-2 | third-row">
            <button
                v-for="btn in thirdRow"
                :key="btn"
                type="button"
                @click.prevent="onBtnClick(btn)"
                :class="[
                    BTN_CLASS,
                    disabledLetters.includes(btn) ? '-disabled' : '',
                    hideLetters.includes(btn) ? '-hide' : '',
                    currentLetterHover === btn ? '-hover' : '',
                    accentRow.includes(btn) ? '-accent' : ''
                ]">
                {{ btn }}
            </button>
        </div>
        <div
            v-if="!hideAccents"
            class="flex md:hidden flex-row justify-start items-center space-x-1 md:space-x-3 md:ml-2 | accents-row">
            <button
                v-for="btn in accentRow"
                :key="btn"
                type="button"
                @click.prevent="onBtnClick(btn)"
                :class="[
                    BTN_CLASS,
                    disabledLetters.includes(btn) ? '-disabled' : '',
                    hideLetters.includes(btn) ? '-hide' : '',
                    currentLetterHover === btn ? '-hover' : ''
                ]">
                {{ btn }}
            </button>
        </div>
        <div
            class="flex flex-row justify-start items-center space-x-1 md:space-x-3"
            v-if="!hideSpace">
            <button
                type="button"
                @click.prevent="space"
                class="-smallest"
                :class="[BTN_CLASS, currentLetterHover === ' ' ? '-hover' : '']">
                {{ $t("global.space") }}
            </button>
            <button
                v-if="!hideRemove"
                type="button"
                @click.prevent="remove"
                :class="[BTN_CLASS, currentLetterHover === '__REMOVE__' ? '-hover' : '']"
                class="-smallest">
                {{ $t("global.delete") }}
            </button>
        </div>
    </div>
</template>

<script>
const BTN_CLASS =
    "flex flex-col justify-center items-center p-1 md:p-4 bg-white rounded border-2 w-full h-16 md:h-full font-bold uppercase"

export default {
    name: "VirtualKeyboard",
    emits: ["update:modelValue", "onKeyPress"],
    props: {
        modelValue: {
            type: String,
            required: false,
            default: ""
        },
        acceptRealKeys: {
            type: Boolean,
            required: false,
            default: true
        },
        disabledLetters: {
            type: Array,
            required: false,
            default: () => []
        },
        hideLetters: {
            type: Array,
            required: false,
            default: () => []
        },
        hideRemove: {
            type: Boolean,
            required: false,
            default: false
        },
        hideAccents: {
            type: Boolean,
            required: false,
            default: false
        },
        hideSpace: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            BTN_CLASS,

            currentLetterHover: null,
            lastKeyClicked: "",

            firstRowWithoutAccents: ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "-"],
            secondRowWithoutAccents: ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
            thirdRowWithoutAccents: ["z", "x", "c", "v", "b", "n", "m", "'"],

            firstRowWithAccents: ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "û", "ô", "-"],
            secondRowWithAccents: ["a", "s", "d", "f", "g", "h", "j", "k", "l", "ç", "î", "ï"],
            thirdRowWithAccents: ["z", "x", "c", "v", "b", "n", "m", "à", "â", "è", "é", "ê", "'"],

            accentRow: ["û", "ô", "ç", "î", "ï", "à", "â", "è", "é", "ê"]
        }
    },
    created() {
        this.currentLetterHoverTimeoutId = null
    },
    mounted() {
        if (this.acceptRealKeys) {
            window.addEventListener("keydown", this.onKeyDown)
        }
    },
    beforeUnmount() {
        window.removeEventListener("keydown", this.onKeyDown)
    },
    computed: {
        firstRow() {
            if (this.hideAccents) {
                return this.firstRowWithoutAccents
            }
            return this.firstRowWithAccents
        },
        secondRow() {
            if (this.hideAccents) {
                return this.secondRowWithoutAccents
            }
            return this.secondRowWithAccents
        },
        thirdRow() {
            if (this.hideAccents) {
                return this.thirdRowWithoutAccents
            }
            return this.thirdRowWithAccents
        },
        allAcceptedKeys() {
            return [...this.firstRow, ...this.secondRow, ...this.thirdRow]
        }
    },
    methods: {
        onKeyDown(e) {
            let key = e.key

            //special keys
            if (e.code === 'Backspace'){
                this.remove()
                return
            }
            if (e.code === 'Space'){
                this.space()
                return
            }

            if (!this.allAcceptedKeys.includes(key)) {
                //todo alert
                return
            }

            this.onBtnClick(key)
        },
        onBtnClick(btn) {
            if (this.lastKeyClicked === btn) return //skip so we can double click

            this.lastKeyClicked = btn
            this.$emit("update:modelValue", this.modelValue + btn)
            this.$emit("onKeyPress", btn)
            this.setHover(btn)

            clearTimeout(this.lastKeyClickedTimeoutId)
            this.lastKeyClickedTimeoutId = setTimeout(() => {
                this.lastKeyClicked = ""
            }, 50)
        },
        setHover(key) {
            this.currentLetterHover = key
            clearTimeout(this.currentLetterHoverTimeoutId)
            this.currentLetterHoverTimeoutId = setTimeout(() => {
                this.currentLetterHover = null
            }, 50)
        },
        remove() {
            this.$emit("update:modelValue", this.modelValue.substring(0, this.modelValue.length - 1))

            this.$emit("onKeyPress", "__REMOVE__")
            this.setHover("__REMOVE__")
        },
        space() {
            this.$emit("update:modelValue", this.modelValue + " ")
            this.$emit("onKeyPress", " ")
            this.setHover(" ")
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.keyboard {
    button {
        touch-action: none;
        user-select: none;

        @apply text-14 text-purple;
        font-weight: 700;
        line-height: 100%;

        @screen lg {
            @apply text-22;
        }

        &.-smallest {
            @apply text-12;
            font-weight: 700;
            line-height: 110%;

            @screen lg {
                @apply text-16;
            }
        }

        &.-hide {
            display: none;
        }

        &.-accent {
            display: none;
            @screen sm {
                display: flex;
            }
        }

        &.-hover {
            @apply bg-gray-200;
        }

        &.-disabled {
            opacity: 0.35;
            pointer-events: none;
        }
    }
}
</style>
